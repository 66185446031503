import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import performRequest from "../../../APIs/request";
import { Toast } from "../../CustomComponent/toast";
const TestResults = ({ answer, test }) => {
  const store = useSelector((store) => store.userLogin);
  const [totalMarks, setTotalMarks] = useState(0);
  const [sameDepTests, setSameDepTests] = useState([]);
  const navigate = useNavigate();
  const handelStartTest = (id) => {
    navigate(`/get-skill-badges/${id}`);
  };
  useEffect(() => {
    if (answer && test) {
      let c = 0;
      for (let i = 0; i < test?.questionsAnsOpt.length; i++) {
        if (answer[i]?.ans === test.questionsAnsOpt[i].answer) {
          c++;
        }
      }
      setTotalMarks(c);
    }
  }, [answer, test]);
  useEffect(() => {
    if (test?.department) {
      let ft = [test?.department];
      performRequest("POST", `/test/alltestsbydepartment`, ft)
        .then((res) => {
          setSameDepTests(res.data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [test]);
  const handelAddResume = () => {
    if (test?.passingScore > totalMarks) {
      Toast("error", "You are not passing the test! please try again.");
    } else {
      let data = {
        skill: test.name,
        icon: test.icon,
        passingScore: test.passingScore,
        totalMarks: totalMarks,
      };
      performRequest(
        "PATCH",
        `/resume/updateResumebyuserid/${store?.user?.UserId}`,
        data
      )
        .then((res) => {
          if (res.status === 201) {
            Toast("success", "Skills added successfully");
          } else if (res.status === 200) {
            Toast("success", "Skills added successfully");
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          Toast("error", "Server Error");
          console.error("Error", err);
        });
    }
  };

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="page-body">
          <div className="container-xxl">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
                CURRENT TEST : {test?.name.toUpperCase()}
              </h2>
              <p className="section-description mb-4 p-0">
                Only pass results will be displayed on your profile. You can
                retake the test after 24Hrs.
              </p>
            </div>
            <div className="row row-cards">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row text-center ">
                      <div className="col-3">
                        <div className="card p-3">
                          <p>YOUR RESULT</p>
                          <h2 className="fs-1">
                            {test?.passingScore <= totalMarks ? "PASS" : "FAIL"}
                          </h2>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="card p-3">
                          <p>CORRECT ANSWERS</p>
                          <h2 className="fs-1">{totalMarks}</h2>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="card p-3">
                          <p>WRONG ANSWERS</p>
                          <h2 className="fs-1">
                            {test?.questionsAnsOpt.length - totalMarks}
                          </h2>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="card p-3">
                          <p>PASSING SCORE</p>
                          <h2 className="fs-1">{test?.passingScore}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        onClick={() => handelStartTest(test?.id)}
                        className="btn btn-primary w-20 my-4 me-2"
                      >
                        RETAKE THE TEST
                      </button>
                      <button
                        onClick={handelAddResume}
                        className="btn btn-primary w-20 my-4 ms-2"
                      >
                        ADD TO MY RESUME
                      </button>
                    </div>
                    {/* <hr className="m-0 p-0" />
                    <h2 className="section-title section-title-lg my-4 text-center p-0">
                      CORRECT ANSWERS FOR TEST : {test?.name.toUpperCase()}
                    </h2>
                    <div className="row">
                      {test?.questionsAnsOpt.map((t, index) => {
                        return (
                          <div className="col-6" key={index}>
                            <div className="card m-1 p-2">
                              <h3 className="m-0 p-0">
                                {index + 1}.{t.question}
                              </h3>
                              <p className="m-0 p-0">a) {t.options?.optionA}</p>
                              <p className="m-0 p-0">b) {t.options?.optionB}</p>
                              <p className="m-0 p-0">c) {t.options?.optionC}</p>
                              <p className="m-0 p-0">d) {t.options?.optionD}</p>
                              <h4 className="m-0 p-0">
                                Answer given: {answer[index].ans}
                              </h4>
                              <h4 className="m-0 p-0">
                                Correct answer: {t.answer}
                              </h4>
                            </div>
                          </div>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h3>More tests for same department:</h3>
                    <div className="row text-center">
                      {sameDepTests?.map((test, index) => {
                        if (index < 6) {
                          return (
                            <div className="col-2" key={test?.id}>
                              <div
                                className="card cursor-pointer"
                                onClick={() =>
                                  navigate(`/get-skill-badges/${test?.id}`)
                                }
                              >
                                <div className="m-2">
                                  <img src={test?.icon} width={"60%"} />
                                </div>
                                <p className="my-2 p-0 fs-3">{test?.name}</p>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TestResults;
