import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Footer from "../../Components/Footer/footer";
import performRequest from "../../APIs/request";
import { useSelector } from "react-redux";
import LoadingPage from "../../Components/layouts/loading";
import SimpleButton from "../../Components/layouts/simple_button";
import { Toast } from "../CustomComponent/toast";

const dummyImages = [
  "https://randomuser.me/api/portraits/men/85.jpg",
  "https://randomuser.me/api/portraits/men/61.jpg",
  "https://randomuser.me/api/portraits/men/62.jpg",
  "https://randomuser.me/api/portraits/men/64.jpg",
  "https://randomuser.me/api/portraits/men/65.jpg",
  "https://randomuser.me/api/portraits/men/67.jpg",
];
const JobBoard = () => {
  const store = useSelector((store) => store?.userLogin);
  const [loading, setLoading] = useState(false);
  const [boardJobs, setBoardJobs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (store?.user?.UserId) {
      setLoading(true);
      performRequest("GET", `/jobs/getboardjobs/${store?.user?.UserId}`)
        .then((res) => {
          setBoardJobs(res.data);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, []);

  const handleJobActiveAndInactive = (status, jobId) => {
    
    const data = { status, jobId };
    performRequest("POST", `/jobs/markjobactive/${store?.user?.UserId}`, data)
      .then((res) => {
        setBoardJobs(res.data);
        let info = ""
        if(status){
          info = "Job Mark as Active"
        }else{
          info = "Job Mark as Inactive "
        }
        Toast("success", `${info}`);
      })
      .catch((err) => console.error(err));
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="page">
        <div className="page-wrapper">
          <div className="container-xxl">
            <div className="page-header">
              <div className="row row deck">
                <div className="col">
                  <h2 className="section-title section-title-lg m-0 p-0">
                    ALL JOB BOARD BY YOU
                  </h2>
                  <p className="section-description mb-4 mt-1 p-0">
                    <span className="status status-green">
                      <span className="status-dot"></span>
                      List of Current Job Posted
                    </span>
                  </p>
                </div>
                <div className="col-auto ms-auto">
                  <SimpleButton
                    buttonText={"Post Job"}
                    onClick={() => navigate("/create-jobs")}
                  />
                </div>
              </div>
            </div>
            <div className="card">
              <div
                className="list-group list-group-flush overflow-auto"
                style={{ maxHeight: "50rem" }}
              >
                {boardJobs &&
                  boardJobs.length > 0 &&
                  boardJobs.map((el, index) => {
                    return (
                      <div className="list-group-item" key={index}>
                        <div className="row">
                          <div className="text-end">
                            <span className="btn btn-light">
                              Total Applications: &nbsp;
                              {el?.candidateapplyandshortlists?.length > 0
                                ? el.candidateapplyandshortlists?.length
                                : 0}{" "}
                            </span>
                          </div>
                          <div className="card m-2 p-2">
                            <div className="row">
                              <div className="col-2 text-center">
                                <div className="card">
                                  <img
                                    src={
                                      el?.employeronboardingmodel
                                        ?.companyLogo ||
                                      "https://i.pinimg.com/280x280_RS/96/44/96/9644965f1f7cbcff611256a4226e22d5.jpg"
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-10">
                                <h3 className="m-0 p-0 d-sm-inline-block">
                                  {el.jobRole}
                                </h3>

                                <p className="m-0 p-0">
                                  at <strong>{el.companyName}</strong> in
                                  <strong>
                                    {" "}
                                    {el.jobRole} &amp; {el.jobDepartment}
                                  </strong>{" "}
                                  department.
                                </p>
                                <span className="badge bg-cyan-lt me-1">
                                  POSITIONS: {el.numberOfPositions}
                                </span>
                                <span className="badge badge-outline text-teal me-1">
                                  {el.workType}
                                </span>
                                <div className="row my-2">
                                  <div className="col-12">
                                    <p className="m-0 p-0">Required Skills:</p>
                                    <div className="card d-flex p-0">
                                      <div className="card-body m-0">
                                        {el?.skillsDetails &&
                                          el?.skillsDetails?.map(
                                            (ele, index) => {
                                              return (
                                                <kbd
                                                  className="m-1"
                                                  key={index}
                                                >
                                                  {ele.skill}{" "}
                                                  {ele.minReqPercentage}
                                                </kbd>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr className="m-0 p-0" />
                                <div className="row mt-2" />
                                <div className="col-9">
                                  <p className="text-secondary m-0 p-0">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 384 512"
                                      fontSize="1em"
                                      className="me-2"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path>
                                    </svg>{" "}
                                    {el.jobCity}, {el.jobState}, Pune.
                                  </p>
                                  <p className="text-secondary m-0 p-0">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 640 512"
                                      fontSize="1em"
                                      className="me-2"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 176c-44.19 0-80-42.99-80-96 0-53.02 35.82-96 80-96s80 42.98 80 96c0 53.03-35.83 96-80 96zm272 48h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z"></path>
                                    </svg>
                                    {el.salaryPerMonthMin} -{" "}
                                    {el.salaryPerMonthMax}
                                  </p>
                                </div>
                                <div
                                  className="col-3"
                                  style={{ width: "100%" }}
                                >
                                  <div className="text-end">
                                    {el?.isActive ? (
                                      <button
                                        onClick={() => {
                                          handleJobActiveAndInactive(
                                            false,
                                            el.id
                                          );
                                        }}
                                        className="btn btn-primary mt-2 me-1"
                                      >
                                        MARK AS INACTIVE
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => {
                                          handleJobActiveAndInactive(
                                            true,
                                            el.id
                                          );
                                        }}
                                        className="btn btn-secondary mt-2 me-1"
                                      >
                                        MARK ACTIVE AGAIN
                                      </button>
                                    )}
                                    <SimpleButton
                                      buttonText={"View Job"}
                                      onClick={() =>
                                        navigate(`/view-jobs/${el.id}`)
                                      }
                                    />
                                    <SimpleButton
                                      buttonText={"Edit Job Post"}
                                      onClick={() =>
                                        navigate(`/edit-jobs/${el.id}`)
                                      }
                                    />
                                    <SimpleButton
                                      buttonText={"candidate comparison"}
                                      onClick={() =>
                                        navigate(
                                          `/candidate-comparison/${el.id}`
                                        )
                                      }
                                    />
                                    <SimpleButton
                                      buttonText={"Applications"}
                                      onClick={() =>
                                        navigate(`/candidate-listing/${el.id}`)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JobBoard;
