import React from "react";
import CustomInput from "../CustomComponent/custom_input";

import { Form, Formik } from "formik";
import { ContactUsInfo } from "../../Schema Info/info";
import { ContactUsSchema } from "../../Schemas/Demo/contact_us_schema";
import CustomTextArea from "../CustomComponent/custom_textarea";
import CustomSelect from "../CustomComponent/custom_select";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import performRequest from "../../APIs/request";

const ContactUs = () => {
  const handelSubmit = (values) => {
    performRequest("POST", "bookdemo/createbookdemo", values)
      .then((res) => {
        if (res.status === 201) {
          alert("Your request taken successfully!");
        }
      })
      .catch((err) => console.error(err));
  };
  return (
    <div className="page-body">
      <div className="container-xxl">
        <div className="card card-lg">
          <div className="card-body ">
            <div className="row g-4">
              <div className="col-12 markdown">
                <h1>Contact Us</h1>
                <p>
                  Hire48 is build by the same team who build Kryzen Biotech. A
                  successful company in Agritech domain. From the same problems
                  we faced in building our team we identified few gaps in the
                  industry processes and decided to build a new product for it.
                </p>
                <p>
                  From startups to SMEs to established enterprises, Apna
                  revolutionizes the way businesses find high-quality talent
                  quickly & effortlessly.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-primary-lt p-5">
          <Formik
            initialValues={ContactUsInfo}
            validationSchema={ContactUsSchema}
            onSubmit={handelSubmit}
          >
            {(props) => (
              <Form>
                <div className="modal-body bg-primary-lt ">
                  <div className="mb-3">
                    <label className="form-label required">Contact As</label>
                    <CustomSelect name="contactAs" type={"text"}>
                      <option value="">Select</option>
                      <option value="Employer">Employer</option>
                      <option value="Employee">Employee</option>
                    </CustomSelect>
                    {props.touched.contactAs && props.errors.contactAs && (
                      <p className="text-danger">{props.errors.contactAs}</p>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label required">Full Name</label>
                    <CustomInput name="fullName" type={"text"} />
                    {props.touched.fullName && props.errors.fullName && (
                      <p className="text-danger">{props.errors.fullName}</p>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label required">Email</label>
                    <CustomInput name="email" type={"email"} />
                    {props.touched.email && props.errors.email && (
                      <p className="text-danger">{props.errors.email}</p>
                    )}
                  </div>

                  <div className="mb-3">
                    <label className="form-label required">
                      Contact Number
                    </label>
                    <CustomInput name="contactNumber" type={"text"} />
                    {props.touched.contactNumber &&
                      props.errors.contactNumber && (
                        <p className="text-danger">
                          {props.errors.contactNumber}
                        </p>
                      )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label required">Message</label>
                    <CustomTextArea name="message" type={"text"} rows={8} />
                    {props.touched.message && props.errors.message && (
                      <p className="text-danger">{props.errors.message}</p>
                    )}
                  </div>
                </div>
                <FormSubmitButton buttonText={"Submit"} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
