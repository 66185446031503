import * as yup from "yup";
const skillSchema = yup.object().shape({
  skill: yup.string(),
  minReqPercentage: yup.number(),
  icon: yup.string(),
});
export const JobsSchema = yup.object().shape({
  companyName: yup.string().required("Job profile is required"),
  employerId: yup.string(),
  jobDepartment: yup.string().required("Job department is required"),
  jobRole: yup.string().required("Job role is required"),
  jobType: yup.string().required("Job type is required"),
  numberOfPositions: yup.number().required("Number of position is required"),
  skillsDetails: yup.array().of(skillSchema).required("skill is required"),
  workType: yup.string().required("work type is required"),
  workShift: yup.string().required("work shift is required"),
  jobLocation: yup.string().required("Job location is required"),
  jobCity: yup.string().required("Job city is required"),
  jobState: yup.string().required("Job state is required"),
  receiveApplicationFrom: yup.string().required("Joining date is required"),
  payType: yup.string().required("Pay type is required"),
  additionalPerks: yup.array().of(yup.string()).required("This field required"),
  joiningFeeOrDeposit: yup.string(),
  salaryPerMonthMax: yup.number().when("payType", {
    is: (val) => val === "Fixed Only"||"Fixed + Incentive",
    then: (s) => s.required("Field is required"),
    otherwise: (s) => s}),
  salaryPerMonthMin: yup.number().when("payType", {
    is: (val) => val === "Fixed Only"||"Fixed + Incentive",
    then: (s) => s.required("Field is required"),
    otherwise: (s) => s}),
  incetivePerMonth: yup.number().when("payType", {
    is: (val) => val === "Fixed + Incentive",
    then: (s) => s.required("Field is required"),
    otherwise: (s) => s}),
  englishLevel: yup.string().required("English level is required"),
  experienceRequired: yup.string().required("Experienced is required"),
  minimumExperience: yup.string(),
  minimumEducation: yup.string().required("Minimum Education is required"),
  JobDescription: yup.string().required("Job Description  is required"),
  interviewType: yup.string(),
  interviewAddress: yup.string().required("Interview address  is required"),
  interviewStartDate: yup.date().required("Interview start date is required"),
  interviewEndDate: yup.date().required("Interview end date is required"),
  interviewEndTime: yup.string(),
  interviewStartTime: yup.string(),
  otherInstructions: yup.string(),
});
