import React from "react";
import { useState, useEffect } from "react";
import performRequest from "../../APIs/request";
import LoadingPage from "../../Components/layouts/loading";
import { extractDate } from "../../Components/Functions/date.fun";
import Pagination from "../../Components/layouts/pagination";
import { setCurrentPage } from "../../Redux//Pagination & Filters/filtersReducer";
import { useDispatch, useSelector } from "react-redux";
function Myapplications() {
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [interviewSchedule, setInterviewSchedule] = useState([]);
  const [interviewData, setInterviewData] = useState();
  const [loading, setLoading] = useState(true);
  const paginationStore = useSelector((store) => store.filters);
  const [page, setCurrPage] = useState(paginationStore.currentPage || 1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    const getMyApplications = () => {
      performRequest(
        "GET",
        `/applyedcandidate/getallapplyedcandidatebyuserid?page=${paginationStore?.currentPage}`
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res.data?.data?.filter((e) => !e.interviewSchedule?.date));
            setTotalPages(Math.ceil(res?.data?.totalData / 50));
            setInterviewSchedule(
              res.data?.data?.filter((e) => e.interviewSchedule?.date)
            );
            setTotalData(res.data?.data);
          }
        })
        .catch((err) => {
          console.error("Error", err);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    };

    getMyApplications();
  }, [paginationStore?.currentPage]);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  useEffect(() => {
    setCurrPage(paginationStore.currentPage);
    setShowPage(Math.floor((paginationStore.currentPage - 1) / 5) * 5 + 1);
  }, [paginationStore.currentPage]);
  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page-wrapper">
      <div className="page-body">
        <div className="container-xl">
          {/* Card header welcome to onboarding */}

          <div className="col-12">
            <div className="card">
              {interviewSchedule && interviewSchedule.length > 0 && (
                <div className="mb-3">
                  <h2 className="m-2">Interview Scheduled</h2>
                  <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                      <thead>
                        <tr>
                          <th>Company Name and Location</th>
                          <th>Job Role</th>
                          <th>Job Type</th>
                          <th>shortlist Status</th>
                          <th>Apply Date</th>
                          <th>Actions</th>
                          <th className="w-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {interviewSchedule &&
                          interviewSchedule.length > 0 &&
                          interviewSchedule.map((el, index) => {
                            return (
                              <tr key={index} className="bg-green-lt">
                                <td data-label="Name">
                                  <div className="d-flex py-1 align-items-center">
                                    <span className="avatar me-2">
                                      <img
                                        src={
                                          el?.jobsmodel?.employeronboardingmodel
                                            ?.companyLogo
                                        }
                                        alt=""
                                        srcSet=""
                                      />
                                    </span>
                                    <div className="flex-fill">
                                      <div className="font-weight-medium">
                                        {
                                          el?.jobsmodel?.employeronboardingmodel
                                            ?.companyName
                                        }
                                        <br />
                                        {el?.jobsmodel.jobCity},{" "}
                                        {el?.jobsmodel.jobState}
                                      </div>
                                      <div className="text-secondary">
                                        <a href="#" className="text-reset">
                                          {el.email}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td data-label="Title">
                                  <div>{el.fullName}</div>
                                  <div className="text-secondary">
                                    {el.jobsmodel.jobRole}
                                  </div>
                                </td>
                                <td
                                  className="text-secondary"
                                  data-label="Role"
                                >
                                  {el.jobsmodel.jobType}
                                </td>
                                <td
                                  className="text-secondary"
                                  data-label="Role"
                                >
                                  {el.shortlistStatus ? (
                                    <span className="badge bg-green-lt">
                                      shortlisted
                                    </span>
                                  ) : (
                                    <span className="badge bg-red-lt">
                                      pending status
                                    </span>
                                  )}
                                </td>
                                <td data-label="Apply Date">
                                  {extractDate(el.createdAt)}
                                </td>
                                <td>
                                  <div className="btn-list flex-nowrap">
                                    <button
                                      data-bs-toggle="modal"
                                      data-bs-target="#modal-team"
                                      onClick={() =>
                                        setInterviewData(el.interviewSchedule)
                                      }
                                      className="btn"
                                    >
                                      View Interview details
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div className="btn-list flex-nowrap">
                                    <a
                                      href={`/view-jobs/${el.jobsmodel.id}`}
                                      className="btn"
                                    >
                                      View Job Post
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {data && data.length > 0 && (
                <>
                  <div>
                    <h2 className="m-2">Jobs Applied</h2>
                    <div className="table-responsive">
                      <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                          <tr>
                            <th>Company Name and Location</th>
                            <th>Job Role</th>
                            <th>Job Type</th>
                            <th>Apply Date</th>
                            <th>shortlist Status</th>
                            <th className="w-1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.length > 0 &&
                            data.map((el, index) => {
                              return (
                                <tr key={index}>
                                  <td data-label="Name">
                                    <div className="d-flex py-1 align-items-center">
                                      <span className="avatar me-2">
                                        <img
                                          src={
                                            el?.jobsmodel
                                              ?.employeronboardingmodel
                                              ?.companyLogo
                                          }
                                          alt=""
                                          srcSet=""
                                        />
                                      </span>
                                      <div className="flex-fill">
                                        <div className="font-weight-medium">
                                          {
                                            el?.jobsmodel
                                              ?.employeronboardingmodel
                                              ?.companyName
                                          }
                                          <br />
                                          {el?.jobsmodel.jobCity},{" "}
                                          {el?.jobsmodel.jobState}
                                        </div>
                                        <div className="text-secondary">
                                          <a href="#" className="text-reset">
                                            {el.email}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>

                                  <td data-label="Title">
                                    <div>{el.fullName}</div>
                                    <div className="text-secondary">
                                      {el.jobsmodel.jobRole}
                                    </div>
                                  </td>
                                  <td
                                    className="text-secondary"
                                    data-label="Role"
                                  >
                                    {el.jobsmodel.jobType}
                                  </td>

                                  <td data-label="Apply Date">
                                    {extractDate(el.createdAt)}
                                  </td>
                                  <td
                                    className="text-secondary"
                                    data-label="Role"
                                  >
                                    {el.shortlistStatus ? (
                                      <span className="badge bg-green-lt">
                                        shortlisted
                                      </span>
                                    ) : (
                                      <span className="badge bg-red-lt">
                                        pending status
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <div className="btn-list flex-nowrap">
                                      <button
                                        onClick={() =>
                                          openInNewTab(
                                            `/view-jobs/${el.jobsmodel.id}`
                                          )
                                        }
                                        className="btn"
                                      >
                                        View Job Post
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div>
                    <Pagination
                      page={page}
                      setPage={setPage}
                      totalPages={totalPages}
                      pageDatalength={totalData?.length}
                      showPage={showPage}
                      setShowPage={setShowPage}
                    />
                  </div>
                </>
              )}
              {data && data.length === 0 && (
                <div className="text-center p-4">
                  <h2>Applications Not Found!</h2>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="modal modal-blur fade"
          id="modal-team"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Schedule Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="card-body">
                  <div className="mb-3 row">
                    <label className="w-33 form-label col-3">
                      Date of Interview:
                    </label>
                    <div className="w-100 col ">{interviewData?.date}</div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-33 form-label col-">
                      Interview Time:
                    </label>
                    <div className="w-100 col ">{interviewData?.time}</div>
                  </div>
                  <div className="mb-3 row">
                    <label className="w-33 form-label col-">
                      Interview Mode:
                    </label>
                    <div className="w-100 col ">
                      {interviewData?.modeofInterview}
                    </div>
                  </div>
                  {interviewData?.modeofInterview ===
                    "telephonic interview" && (
                    <div className="mb-3 row">
                      <label className="w-33 form-label col-">
                        Contact Number:
                      </label>
                      <div className="w-100 col ">
                        {interviewData?.contactNumber}
                      </div>
                    </div>
                  )}
                  {interviewData?.modeofInterview === "walk-in interview" && (
                    <div className="mb-3 row">
                      <label className="w-33 form-label col-">
                        Interview Address:
                      </label>
                      <div className="w-100 col ">{interviewData?.address}</div>
                    </div>
                  )}
                  {interviewData?.modeofInterview === "video interview" && (
                    <div className="mb-3 row">
                      <label className="w-33 form-label col-">
                        Meeting Link:
                      </label>
                      <div className="w-100 col ">
                        {interviewData?.meetLink}
                      </div>
                    </div>
                  )}
                  {interviewData?.contactPersonName && (
                    <div className="mb-3 row">
                      <label className="w-33 form-label col-">
                        Contact Person:
                      </label>
                      <div className="w-100 col ">
                        {interviewData?.contactPersonName}
                      </div>
                    </div>
                  )}
                  {interviewData?.contactPersonNumber && (
                    <div className="mb-3 row">
                      <label className="w-33 form-label col-">
                        Contact Person Number:
                      </label>
                      <div className="w-100 col ">
                        {interviewData?.contactPersonNumber}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn me-auto"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Myapplications;
