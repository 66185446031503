import React from 'react'
import Webcam from "react-webcam";
import { useState, useEffect } from 'react';
import { useMediaDevices } from 'react-use';
function AiInterview() {
const [audioButton, setAudioButton] = useState(false);
const [micIcon, setMicIcon] = useState(false)
const {microphone} = useMediaDevices()

useEffect(()=>{








    
})
    
  return (
     <div class="page">

  <div class="page-wrapper">
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-deck row-cards">
          <div class="col-6">
            <div class="card">
              <div class="card-body align-item center" style={{height:"10rem", objectFit:"cover"}}>
              <img width={"100%"} height={"100%"} src="https://avatars.githubusercontent.com/u/80870870?v=4" alt="" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body" >
              <Webcam style={{width:"98%", borderRadius:"12px"}} audio={true} />
              </div>
            </div>
          </div>
           <div className="col-12">
              <div className="card col-6">one</div>
              <div className="card col-6">
                <div className="card-body">
                    {audioButton?( <button className='btn btn-primary ms-2 me-2'>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="100"  height="100"  viewBox="0 0 24 24"  fill="currentColor"  class="icon icon-tabler icons-tabler-filled icon-tabler-microphone"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19 9a1 1 0 0 1 1 1a8 8 0 0 1 -6.999 7.938l-.001 2.062h3a1 1 0 0 1 0 2h-8a1 1 0 0 1 0 -2h3v-2.062a8 8 0 0 1 -7 -7.938a1 1 0 1 1 2 0a6 6 0 0 0 12 0a1 1 0 0 1 1 -1m-7 -8a4 4 0 0 1 4 4v5a4 4 0 1 1 -8 0v-5a4 4 0 0 1 4 -4" /></svg>
                    </button>

                    ):( <button className='btn btn-primary ms-2 me-2'>
                   
                   <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-microphone-2-off"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M16.908 12.917a5 5 0 1 0 -5.827 -5.819" /><path d="M10.116 10.125l-6.529 7.46a2 2 0 1 0 2.827 2.83l7.461 -6.529" /><path d="M3 3l18 18" /></svg>
                   </button>
                  )}
                   
                   
                    {audioButton?(<button className='btn btn-primary ms-2 me-2' onClick={()=> setAudioButton(false)}>Stop</button>):( <button className='btn btn-primary ms-2 me-2' onClick={()=> setAudioButton(true)}>Start</button> )}
                   
                </div>
              
              </div>
           </div>
          <div class="col-12">
            <div class="card">
              <div class="card-body" style={{height:"10rem"}}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


      </div> 
  )
}

export default AiInterview