import React from "react";
import { Link } from "react-router-dom";
import { FaMoon, FaSun } from "react-icons/fa";
const linkStyle = {
  textDecoration: "none",
  color: "white",
};

const FooterBottom = () => {
  return (
    <div className="bg-dark">
      <div className="container-xxl">
        <div className="row">
          <div className="col-12">
            <p>
              Copyright © 2024 HIRE48.com | All rights reserved. All trademarks
              & logos are the property of their respective owners.
            </p>
            <p>
              HIRE48 does not charge any fee at any stage of its recruitment
              process from the candidate, nor does it allow its employees to
              collect any fees from any candidates. HIRE48 is an equal
              employment opportunity employer. All qualified applicants receive
              consideration for employment without regard to race, colour,
              religion, sex, sexual orientation, gender identity or expression,
              appearance, national origin, age, marital status, veteran status,
              disability status, or any other characteristics. We are driven by
              our promise of human progress.
            </p>
            <p>
              <Link to={"/about-us"} style={linkStyle}>
                {" "}
                About us{" "}
              </Link>{" "}
              |{" "}
              <Link to={"/contact-us"} style={linkStyle}>
                Contact us
              </Link>{" "}
              |{" "}
              <Link to={"/success-stories"} style={linkStyle}>
                Success Stories
              </Link>{" "}
              |{" "}
              <Link to={"/terms-and-conditions"} style={linkStyle}>
                Terms & Conditions{" "}
              </Link>
              |{" "}
              <Link to={"/privacy-policy"} style={linkStyle}>
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link to={"/faqs"} style={linkStyle}>
                FAQ{" "}
              </Link>
              |{" "}
              <Link to={"/pricing"} style={linkStyle}>
                Pricing{" "}
              </Link>
              |{" "}
              <Link to={"/refer-and-earn"} style={linkStyle}>
                Refer & Earn
              </Link>
            </p>
            <div className="my-2">
              <a
                href="?theme=dark"
                className="btn btn-secondary btn-sm p-2 hide-theme-dark"
                title="Enable dark mode"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
              >
                <FaMoon fontSize={"1em"} className="me-1" /> Change to Dark Mode
              </a>

              <a
                href="?theme=light"
                className="btn p-2 hide-theme-light"
                title="Enable light mode"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
              >
                <FaSun fontSize={"1em"} className="me-1" /> Change to Light Mode
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
