import axios from "axios";
import Cookies from "js-cookie";
import { backendUrl } from "../Config/config";

const axiosInstance = axios.create({
  baseURL: backendUrl,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const cookie = Cookies.get("user") || null;
    let userData = "";
    if (cookie) {
      userData = JSON.parse(cookie);
    }
    // Add authorization header if token exists in localStorage
    // const token = useSelector((state) => state.login.user.token);
    if (userData?.Token) {
      config.headers.Authorization = `Bearer ${userData?.Token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { axiosInstance };
