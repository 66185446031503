import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import SimpleButton from "../../../Components/layouts/simple_button";
import { useNavigate, useParams } from "react-router";
import performRequest from "../../../APIs/request";
import GetRandomUserImage from "./get_random_user_image";
import { useSelector } from "react-redux";
const test = {
  id: 1,
  type: "",
  level: "basic",
  icon: "",
  name: "Access",
  description:
    "Title: Basic Access Skills Assessment for Software & Product Engineering\r\n\r\nObjective:\r\nThe objective of this test is to assess the basic skills and knowledge of candidates in using Microsoft Access, particularly for roles in the Software & Product Engineering department. This test is designed to ensure that candidates have a foundational understanding of Access to manage databases, create queries, forms, and reports, and understand key concepts such as relationships, primary keys, and data integrity.\r\n\r\nInstructions:\r\nThis test consists of 20 multiple-choice questions.\r\nEach question has four options, with only one correct answer.\r\nSelect the most appropriate answer for each question.\r\nThere is no negative marking for incorrect answers.\r\nThe test is timed; you will have 30 minutes to complete all questions.",
  passingScore: "20",
  questionsAnsOpt: [
    {
      answer: "optionB",
      options: {
        optionA: " Creating websites",
        optionB: "Managing databases",
        optionC: "Editing videos",
        optionD: "Designing graphics",
      },
      question: "What is Microsoft Access primarily used for?",
    },
    {
      answer: "optionA",
      options: {
        optionA: " A unique identifier for each record in a table",
        optionB: "A key to open the software",
        optionC: "A tool to format text",
        optionD: "A method to import data",
      },
      question: "What is a primary key in Access ?",
    },
    {
      answer: "optionC",
      options: {
        optionA: "Number",
        optionB: "Currency",
        optionC: "Text",
        optionD: "Date/Time",
      },
      question: " Which of the following data types can store text in Access?",
    },
    {
      answer: "optionB",
      options: {
        optionA: "To print documents",
        optionB: " To filter and analyze data",
        optionC: "To create charts",
        optionD: "To design forms",
      },
      question: "In Access, what is a query used for?",
    },
    {
      answer: "optionB",
      options: {
        optionA: "A connection between two fields in the same table",
        optionB: " A connection between two tables",
        optionC: "A formula for calculations",
        optionD: "A type of query",
      },
      question: "What does the term 'relationship' mean in Access?",
    },
    {
      answer: "optionB",
      options: {
        optionA: "Using the 'Data' tab in Excel",
        optionB: "Using the 'Import & Link' group in the 'External Data' tab",
        optionC: "Using the 'File' tab",
        optionD: " Using the 'Design View'",
      },
      question: "How can you import data into an Access database?",
    },
    {
      answer: "optionA",
      options: {
        optionA: " A method to display and enter data in a table",
        optionB: "A way to print data",
        optionC: " A type of relationship",
        optionD: " A type of query",
      },
      question: "What is a form in Access?",
    },
    {
      answer: "optionC",
      options: {
        optionA: "They allow users to modify table structures",
        optionB: "They are used to create queries",
        optionC: "They provide a formatted printout of data",
        optionD: "They are used to enter data",
      },
      question: "Which of the following is a feature of Access reports?",
    },
    {
      answer: "optionB",
      options: {
        optionA: " Using the 'New' button in the 'Home' tab",
        optionB: "Using the 'Table Design' view",
        optionC: "Using the 'Layout' view",
        optionD: "Using the 'Form Design' view",
      },
      question: "How can you create a new table in Access?",
    },
    {
      answer: "optionC",
      options: {
        optionA: "To perform calculations",
        optionB: " To create queries",
        optionC: "To simplify data entry by creating dropdown lists",
        optionD: "To format reports",
      },
      question: "What is the purpose of the 'Lookup Wizard' in Access?",
    },
    {
      answer: "optionA",
      options: {
        optionA: "A column in a table",
        optionB: "A row in a table",
        optionC: "A database",
        optionD: " A type of query",
      },
      question: " What does the term 'field' refer to in Access?",
    },
    {
      answer: "optionB",
      options: {
        optionA: " Design View",
        optionB: "Datasheet View",
        optionC: "Layout View",
        optionD: " Print Preview",
      },
      question: "Which view in Access allows you to see the data in a table?",
    },
    {
      answer: "optionA",
      options: {
        optionA: "255",
        optionB: "512",
        optionC: "1024",
        optionD: "2048",
      },
      question:
        " What is the maximum number of characters you can store in a Text field in Access?",
    },
    {
      answer: "optionC",
      options: {
        optionA: "Click the 'Save' button on the toolbar",
        optionB: "Click 'File' then 'Save'",
        optionC: " Click 'File' then 'Save As'",
        optionD: "Click 'Home' then 'Save'",
      },
      question: " How do you save a database in Access?",
    },
    {
      answer: "optionC",
      options: {
        optionA: "Text",
        optionB: "Number",
        optionC: "Email",
        optionD: " Date/Time",
      },
      question: " Which of the following is NOT a valid data type in Access?",
    },
    {
      answer: "optionA",
      options: {
        optionA: " Using the 'Relationship' button in the 'Database Tools' tab",
        optionB: " Using the 'New' button in the 'Home' tab",
        optionC: "Using the 'Form Design' view",
        optionD: " Using the 'Table Design' view",
      },
      question:
        "How do you create a relationship between two tables in Access?",
    },
    {
      answer: "optionA",
      options: {
        optionA: " A small program to automate tasks",
        optionB: " A type of table",
        optionC: "A type of query",
        optionD: " A type of form",
      },
      question: "What is a macro in Access?",
    },
    {
      answer: "optionB",
      options: {
        optionA: "Form Design",
        optionB: " Relationships",
        optionC: " Import & Link",
        optionD: "Datasheet View",
      },
      question: "Which tool can you use to ensure data integrity in Access?",
    },
    {
      answer: "optionA",
      options: {
        optionA: "Using the 'Report Wizard'",
        optionB: " Using the 'Query Wizard'",
        optionC: " Using the 'Form Wizard'",
        optionD: " Using the 'Macro Wizard'",
      },
      question: "How can you create a report in Access?",
    },
    {
      answer: "optionB",
      options: {
        optionA: " It backs up the database",
        optionB: " It reduces the size of the database and repairs any issues",
        optionC: " It formats the database",
        optionD: "It exports the database",
      },
      question: "What does the 'Compact and Repair' tool in Access do?",
    },
  ],
  department: "Software Engineering",
  createdAt: "2024-07-15T12:07:05.000Z",
  updatedAt: "2024-07-15T12:07:05.000Z",
};
const getRandomImage = (max = 10, data = []) => {
  let arr = [];
  for (let i = 0; i < max; i++) {
    let x = Math.floor(Math.random() * 480);
    if (data && data[x] && data[x].squareLogo) {
      arr.push(data[x]);
    }
  }
  return arr;
};
const TestDetails = () => {
  const store = useSelector((store) => store?.userLogin);
  const [data, setData] = useState(test);
  const [sameDepTests, setSameDepTests] = useState([]);
  const [demoComp, setDemoComp] = useState([]);
  const navigate = useNavigate();
  let { id } = useParams();
  const [userData, setuserData] = useState([]);
  useEffect(() => {
    performRequest("GET", "/democomp/alldemocompany")
      .then((res) => {
        if (res.status === 200) {
          setDemoComp(res?.data);
        }
      })
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    const getResumeBasedOnSkills = () => {
      performRequest("GET", "/demouser/alldemouser")
        .then((res) => {
          setuserData(res.data);
        })
        .catch((err) => {
          console.error("Error", err);
        });
    };
    getResumeBasedOnSkills();
  }, []);
  useEffect(() => {
    performRequest("GET", `/test/testsbyid/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, [id]);
  const handelStartTest = () => {
    navigate(`/get-skill-badges/take-test/${id}`);
  };
  useEffect(() => {
    if (data?.department) {
      let ft = [data?.department];
      performRequest("POST", `/test/alltestsbydepartment`, ft)
        .then((res) => {
          setSameDepTests(res.data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [data]);

  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="page-body">
          <div className="container-xxl">
            <div className="row row-cards">
              <div className="col-12 ">
                <div className="section-header">
                  <h2 className="section-title section-title-lg m-0 p-0">
                    GET YOURSELF BADGED.
                  </h2>
                  <p className="section-description mb-4 p-0">
                    On finishing this test you will be rewarded with badge and
                    certificate for having skills in {data?.name}.
                  </p>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="card">
                          {/* <div className="w-100 col">
                            {data?.icon ? (
                              <img
                                src={data?.icon}
                                width={"200px"}
                                height={"200px"}
                                alt={test.name}
                              />
                            ) : (
                              "N/A"
                            )}
                          </div> */}
                          <div className="text-center">
                            <img width={"80%"} src={data?.icon} />
                          </div>
                          <div className="card-body">
                            <h3 className="card-title m-0 p-0 fs-2">
                              {data?.name}
                            </h3>
                            <p className="m-0 p-0">Test Designed By: HIRE48</p>
                            <p>4.5 Stars</p>
                            <p>
                              <span className="badge badge-md bg-lime-lt">
                                {(data?.level || "N/A").toUpperCase()} LEVEL
                              </span>
                            </p>

                            <div className="col-auto">
                              <div className="avatar-list avatar-list-stacked ">
                                {userData && (
                                  <GetRandomUserImage
                                    max={Math.floor(5 * Math.random() + 8)}
                                    userData={userData}
                                    classes={"avatar avatar-md rounded"}
                                  />
                                )}
                              </div>
                              <span>
                                +{Math.floor(Math.random() * 900)} Enrolled
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <h3 className="m-0 p-0">Description:</h3>
                        <p className="text-secondary">
                          {data?.description || "N/A"}
                        </p>
                        <hr className="my-2 p-0" />
                        <div className="row">
                          <div className="col-md-2 ">
                            <p className="fs-4  fw-bold mb-1 p-0">
                              Companies currently hiring for this skill:
                            </p>
                          </div>
                          <div className="col-md-10">
                            {getRandomImage(10, demoComp)?.map(
                              (item, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      backgroundImage: `url(${item?.squareLogo})`,
                                    }}
                                    className="avatar avatar-md mx-2 p-0"
                                  ></div>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <hr className="my-2 p-0" />

                        <h3 className="my-2 p-0">
                          Departments:{" "}
                          <span className="badge badge-lg bg-lime-lt">
                            {data?.department}
                          </span>
                        </h3>
                        <hr className="my-2 p-0" />
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="card text-center my-2 py-2">
                                  <h3 className="m-0 p-0 fs-1">
                                    {data?.questionsAnsOpt.length}
                                  </h3>
                                  <p className="m-0 p-0 fs-4">QUESTIONS</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="card text-center my-2 py-2">
                                  <h3 className="m-0 p-0 fs-1">
                                    {data?.questionsAnsOpt.length}
                                  </h3>
                                  <p className="m-0 p-0 fs-4">MINUTES</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="card text-center my-2 py-2">
                                  <h3 className="m-0 p-0 fs-1">1</h3>
                                  <p className="m-0 p-0 fs-4">MARK/Q</p>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="card text-center my-2 py-2">
                                  <h3 className="m-0 p-0 fs-1">NO</h3>
                                  <p className="m-0 p-0 fs-4">-VE MARKS</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 text-md-end">
                            <p className="m-0 p-0">
                              Once you start the test, You can not refresh or
                              stop inbetween.
                            </p>
                            {(store?.user?.Role === 'recruiter')?(
                             <button disabled className="btn btn-primary">
                              START YOUR TEST
                             </button>
                            ):(

                              <SimpleButton
                              buttonText={"Start Your Test"}
                              
                              onClick={() => {
                                if (store?.isLoggedIn) {
                                  handelStartTest();
                                } else {
                                  alert(
                                    "Please login before starting your test"
                                  );
                                  navigate("/account-login");
                                }
                              }}
                            />
                            )  }
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row my-2">
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h3>More tests for same department:</h3>
                    <div className="row row-deck text-center">
                      {sameDepTests?.map((test, index) => {
                        if (index < 6) {
                          return (
                            <div className="col-md-2 mb-3" key={index}>
                              <div
                                className="card cursor-pointer"
                                onClick={() =>
                                  navigate(`/get-skill-badges/${test?.id}`)
                                }
                              >
                                <div className="m-2">
                                  <img src={test?.icon} width={"60%"} />
                                </div>
                                <p className="my-2 p-0 fs-3">{test?.name}</p>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TestDetails;
