import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import Scrollbar from "../../Components/layouts/Scrollbar";
import { FaAddressCard, FaSearch, FaAngleDoubleRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import performRequest from "../../APIs/request";
import { useSelector } from "react-redux";
const Dashboard = () => {
  const store = useSelector((store) => store?.userLogin);
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState([]);
  const [testsData, setTeastData] = useState([]);
  const [demoComp, setDemoComp] = useState([]);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  useEffect(() => {
    performRequest("GET", `/test/alltests`)
      .then((res) => {
        setTeastData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    performRequest("GET", `/jobs/getalljobs`)
      .then((res) => {
        setJobsData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    performRequest("GET", "/democomp/alldemocompany")
      .then((res) => {
        if (res.status === 200) {
          setDemoComp(res?.data);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  let se1 = 0;
  let sbd1 = 0;
  let abc1 = 0;
  return (
    <div className="Page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <header className="hero text-center py-10 my-6 my-md-8">
            <h1
              className="hero-title aos-init aos-animate my-5 display-4 fw-bold"
              data-aos="zoom-y-out"
            >
              YOUR JOB SEARCH ENDS HERE!
            </h1>

            <div className="row my-4">
              <div className="col-mg-12 col-lg-6 text-lg-end text-md-center mb-3">
                <div className="">
                  <span
                    onClick={() => {
                      if (!store?.isLoggedIn) {
                        navigate("/account-login");
                      } else {
                        navigate("/resume-builder");
                      }
                    }}
                    className="btn btn-primary btn-lg p-3 btn-text"
                  >
                    <FaAddressCard fontSize={"1.2em"} className="me-2" /> BUILD
                    YOUR PROFILE
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 text-lg-start text-md-center">
                <div className="">
                  <span
                    className="btn btn-lg p-3 btn-text"
                    onClick={() => {
                      navigate("/top-hires");
                    }}
                  >
                    <FaSearch fontSize={"1.2em"} className="me-2" />
                    BROWSE JOB POSTS
                  </span>
                </div>
              </div>
            </div>

            <p className="hero-description text-secondary fs-2">
              Discover perfect job in 48Hrs.
            </p>
          </header>
        </div>
        <Scrollbar direction={"right"}></Scrollbar>
        <Scrollbar direction={"left"}></Scrollbar>
        <div className="container-xxl">
          <hr />
          <section className="section text-center my-3 my-md-8">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0 fs-1">
                NOT JUST A JOB PLATFORM?
              </h2>
              <p className="section-description m-0 p-0">
                We are not just focused on creating list of jobs. We prepare you
                well in advance to make sure you crack the interview.
              </p>
            </div>
            <div className="my-4">
              <div className="row my-3" data-aos-id-blocks-previews="true">
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div
                    className="card card-stacked card-link card-link-pop mb-3"
                    style={{ height: "220px" }}
                  >
                    <div className="card-body ">
                      <h2 className="fs-2">NO FAKE JOBS</h2>
                      <p>
                        We only work with GST Registered Companies. We post zero
                        fake jobs and close every position in less then 36Hrs.
                        Reducing your hire time & hassle.
                      </p>
                      <a
                        href="/verified-profiles"
                        className="btn btn-outline-primary w-50 btn-sm mt-3"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div
                    className="card card-stacked card-link card-link-pop mb-3"
                    style={{ height: "220px" }}
                  >
                    <div className="card-body">
                      <h3 className="fs-2">DEDICATED MANAGER</h3>
                      <p>
                        We assign you dedicated account manager once you get
                        shortlisted. We ensure that you are prepared for the
                        interview from core skills to soft skills.
                      </p>
                      <a
                        href="/free-account-manager"
                        className="btn btn-outline-primary w-50 btn-sm mt-3"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div
                    className="card card-stacked card-link card-link-pop mb-3"
                    style={{ height: "220px" }}
                  >
                    <div className="card-body">
                      <h3 className="fs-2">SKILLS VERIFICATION</h3>
                      <p>
                        We build strong repo with clients and help you stand out
                        from crowd with skill-test. Give tests and get your
                        skills badges to improve your skill score.
                      </p>
                      <a
                        href="/verified-skills"
                        className="btn btn-outline-primary w-50 btn-sm mt-3"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6 ">
                  <div
                    className="card card-stacked card-link card-link-pop mb-3"
                    style={{ height: "220px" }}
                  >
                    <div className="card-body">
                      <h3 className="fs-2">AI BASED INTERVIEWS</h3>
                      <p>
                        We have identified set of questions you need to prepare
                        in order to convey about yourself in most effective
                        manner. With help of AI Mocks prepare faster.
                      </p>
                      <a
                        href="/ai-interview-cracker"
                        className="btn btn-outline-primary w-50 btn-sm"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr />

          <div className="row my-5 my-md-8">
            <div className="col-md-12">
              <div className="card">
                <h2 className="my-5 fw-bold text-center">
                  SEARCH JOB IN YOUR DREAM CITY!
                </h2>
                <div className="card-header">
                  <ul
                    className="nav nav-tabs card-header-tabs nav-fill"
                    data-bs-toggle="tabs"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        href="#bangalore"
                        className="nav-link"
                        data-bs-toggle="tab"
                        aria-selected="false"
                        role="tab"
                      >
                        Bangalore
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        href="#mumbai"
                        className="nav-link"
                        data-bs-toggle="tab"
                        aria-selected="false"
                        role="tab"
                        tabIndex="-1"
                      >
                        Mumbai
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        href="#pune"
                        className="nav-link active"
                        data-bs-toggle="tab"
                        aria-selected="false"
                        role="tab"
                        tabIndex="-1"
                      >
                        Pune
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        href="#chennai"
                        className="nav-link"
                        data-bs-toggle="tab"
                        aria-selected="false"
                        role="tab"
                        tabIndex="-1"
                      >
                        Chennai
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        href="#hyderabad"
                        className="nav-link"
                        data-bs-toggle="tab"
                        aria-selected="false"
                        role="tab"
                        tabIndex="-1"
                      >
                        Hyderabad
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        href="#kolkata"
                        className="nav-link"
                        data-bs-toggle="tab"
                        aria-selected="false"
                        role="tab"
                        tabIndex="-1"
                      >
                        Kolkata
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        href="#delhi"
                        className="nav-link"
                        data-bs-toggle="tab"
                        aria-selected="false"
                        role="tab"
                        tabIndex="-1"
                      >
                        Delhi
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        href="#ahmedabad"
                        className="nav-link"
                        data-bs-toggle="tab"
                        aria-selected="false"
                        role="tab"
                        tabIndex="-1"
                      >
                        Ahmedabad
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="tab-content">
                    <div
                      className="tab-pane show"
                      id="bangalore"
                      role="tabpanel"
                    >
                      <h4>100+ Job Openings in Bangalore</h4>

                      <div className="row row-deck">
                        {jobsData.map((jobs) => {
                          if (
                            jobs.jobCity?.trim().toLowerCase() === "bangalore"
                          ) {
                            return (
                              <div key={jobs.id} className="col-lg-4 col-md-6">
                                <div className="card my-2">
                                  <div className="row g-0">
                                    <div className="col-auto">
                                      <div className="card-body">
                                        <div className="avatar avatar-md"></div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="card-body ps-0">
                                        <div className="row">
                                          <div className="col">
                                            <h3 className="mb-0">
                                              {jobs?.jobRole}
                                            </h3>
                                            <p>{jobs?.companyName}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md">
                                            <div className="mt-1 badges">
                                              {jobs?.skillsDetails?.map(
                                                (skill) => {
                                                  return (
                                                    <button
                                                      key={skill.skill}
                                                      className="m-1 badge badge-outline text-secondary fw-normal badge-pill"
                                                    >
                                                      {skill.skill}
                                                    </button>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-auto ">
                                            <button
                                              onClick={() => {
                                                if (!store?.isLoggedIn) {
                                                  alert(
                                                    "You must be logged before applying this job."
                                                  );
                                                  navigate("/account-login");
                                                } else {
                                                  openInNewTab(
                                                    `/view-jobs/${jobs?.id}`
                                                  );
                                                }
                                              }}
                                              className="btn btn-primary "
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane show" id="mumbai" role="tabpanel">
                      <h4>100+ Job Openings in Mumbai</h4>

                      <div className="row row-deck">
                        {jobsData.map((jobs) => {
                          if (jobs.jobCity?.trim().toLowerCase() === "mumbai") {
                            return (
                              <div key={jobs.id} className="col-lg-4 col-md-6">
                                <div className="card my-2">
                                  <div className="row g-0">
                                    <div className="col-auto">
                                      <div className="card-body">
                                        <div className="avatar avatar-md"></div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="card-body ps-0">
                                        <div className="row">
                                          <div className="col">
                                            <h3 className="mb-0">
                                              {jobs?.jobRole}
                                            </h3>
                                            <p>{jobs?.companyName}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md">
                                            <div className="mt-1 badges">
                                              {jobs?.skillsDetails?.map(
                                                (skill) => {
                                                  return (
                                                    <button
                                                      key={skill.skill}
                                                      className="m-1 badge badge-outline text-secondary fw-normal badge-pill"
                                                    >
                                                      {skill.skill}
                                                    </button>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-auto">
                                            <button
                                              onClick={() => {
                                                if (!store?.isLoggedIn) {
                                                  alert(
                                                    "You must be logged before applying this job."
                                                  );
                                                  navigate("/account-login");
                                                } else {
                                                  openInNewTab(
                                                    `/view-jobs/${jobs?.id}`
                                                  );
                                                }
                                              }}
                                              className="btn btn-primary"
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane active show"
                      id="pune"
                      role="tabpanel"
                    >
                      <h4>100+ Job Openings in Pune</h4>

                      <div className="row row-deck">
                        {jobsData.map((jobs) => {
                          if (jobs.jobCity?.trim().toLowerCase() === "pune") {
                            return (
                              <div key={jobs.id} className="col-lg-4 col-md-6">
                                <div className="card my-2">
                                  <div className="row g-0">
                                    <div className="col-auto">
                                      <div className="card-body">
                                        <div className="avatar avatar-md"></div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="card-body ps-0">
                                        <div className="row">
                                          <div className="col">
                                            <h3 className="mb-0">
                                              {jobs?.jobRole}
                                            </h3>
                                            <p>{jobs?.companyName}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md">
                                            <div className="mt-1 badges">
                                              {jobs?.skillsDetails?.map(
                                                (skill) => {
                                                  return (
                                                    <button
                                                      key={skill.skill}
                                                      className="m-1 badge badge-outline text-secondary fw-normal badge-pill"
                                                    >
                                                      {skill.skill}
                                                    </button>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-auto mt-3 mt-lg-0 mt-md-0">
                                            <button
                                              onClick={() => {
                                                if (!store?.isLoggedIn) {
                                                  alert(
                                                    "You must be logged before applying this job."
                                                  );
                                                  navigate("/account-login");
                                                } else {
                                                  openInNewTab(
                                                    `/view-jobs/${jobs?.id}`
                                                  );
                                                }
                                              }}
                                              className="btn btn-primary"
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane show" id="chennai" role="tabpanel">
                      <h4>100+ Job Openings in Chennai</h4>

                      <div className="row row-deck">
                        {jobsData.map((jobs) => {
                          if (
                            jobs.jobCity?.trim().toLowerCase() === "chennai"
                          ) {
                            return (
                              <div key={jobs.id} className="col-lg-4 col-md-6">
                                <div className="card my-2">
                                  <div className="row g-0">
                                    <div className="col-auto">
                                      <div className="card-body">
                                        <div className="avatar avatar-md"></div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="card-body ps-0">
                                        <div className="row">
                                          <div className="col">
                                            <h3 className="mb-0">
                                              {jobs?.jobRole}
                                            </h3>
                                            <p>{jobs?.companyName}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md">
                                            <div className="mt-1 badges">
                                              {jobs?.skillsDetails?.map(
                                                (skill) => {
                                                  return (
                                                    <button
                                                      key={skill.skill}
                                                      className="m-1 badge badge-outline text-secondary fw-normal badge-pill"
                                                    >
                                                      {skill.skill}
                                                    </button>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-auto">
                                            <button
                                              onClick={() => {
                                                if (!store?.isLoggedIn) {
                                                  alert(
                                                    "You must be logged before applying this job."
                                                  );
                                                  navigate("/account-login");
                                                } else {
                                                  openInNewTab(
                                                    `/view-jobs/${jobs?.id}`
                                                  );
                                                }
                                              }}
                                              className="btn btn-primary"
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane show"
                      id="hyderabad"
                      role="tabpanel"
                    >
                      <h4>100+ Job Openings in hyderabad</h4>

                      <div className="row row-deck">
                        {jobsData.map((jobs) => {
                          if (
                            jobs.jobCity?.trim().toLowerCase() === "hyderabad"
                          ) {
                            return (
                              <div key={jobs.id} className="col-lg-4 col-md-6 ">
                                <div className="card my-2">
                                  <div className="row g-0">
                                    <div className="col-auto">
                                      <div className="card-body">
                                        <div className="avatar avatar-md"></div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="card-body ps-0">
                                        <div className="row">
                                          <div className="col">
                                            <h3 className="mb-0">
                                              {jobs?.jobRole}
                                            </h3>
                                            <p>{jobs?.companyName}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md">
                                            <div className="mt-1 badges">
                                              {jobs?.skillsDetails?.map(
                                                (skill) => {
                                                  return (
                                                    <button
                                                      key={skill.skill}
                                                      className="m-1 badge badge-outline text-secondary fw-normal badge-pill"
                                                    >
                                                      {skill.skill}
                                                    </button>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-auto">
                                            <button
                                              onClick={() => {
                                                if (!store?.isLoggedIn) {
                                                  alert(
                                                    "You must be logged before applying this job."
                                                  );
                                                  navigate("/account-login");
                                                } else {
                                                  openInNewTab(
                                                    `/view-jobs/${jobs?.id}`
                                                  );
                                                }
                                              }}
                                              className="btn btn-primary"
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane show" id="kolkata" role="tabpanel">
                      <h4>100+ Job Openings in Kolkata</h4>
                      <div className="row row-deck">
                        {jobsData.map((jobs) => {
                          if (
                            jobs.jobCity?.trim().toLowerCase() === "kolkata"
                          ) {
                            return (
                              <div key={jobs.id} className="col-lg-4 col-md-6">
                                <div className="card my-2">
                                  <div className="row g-0">
                                    <div className="col-auto">
                                      <div className="card-body">
                                        <div className="avatar avatar-md"></div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="card-body ps-0">
                                        <div className="row">
                                          <div className="col">
                                            <h3 className="mb-0">
                                              {jobs?.jobRole}
                                            </h3>
                                            <p>{jobs?.companyName}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md">
                                            <div className="mt-1 badges">
                                              {jobs?.skillsDetails?.map(
                                                (skill) => {
                                                  return (
                                                    <button
                                                      key={skill.skill}
                                                      className="m-1 badge badge-outline text-secondary fw-normal badge-pill"
                                                    >
                                                      {skill.skill}
                                                    </button>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-auto">
                                            <button
                                              onClick={() => {
                                                if (!store?.isLoggedIn) {
                                                  alert(
                                                    "You must be logged before applying this job."
                                                  );
                                                  navigate("/account-login");
                                                } else {
                                                  openInNewTab(
                                                    `/view-jobs/${jobs?.id}`
                                                  );
                                                }
                                              }}
                                              className="btn btn-primary"
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane show" id="delhi" role="tabpanel">
                      <h4>100+ Job Openings in Delhi</h4>
                      <div className="row row-deck">
                        {jobsData.map((jobs) => {
                          if (jobs.jobCity?.trim().toLowerCase() === "delhi") {
                            return (
                              <div key={jobs.id} className="col-lg-4 col-md-6">
                                <div className="card my-2">
                                  <div className="row g-0">
                                    <div className="col-auto">
                                      <div className="card-body">
                                        <div className="avatar avatar-md"></div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="card-body ps-0">
                                        <div className="row">
                                          <div className="col">
                                            <h3 className="mb-0">
                                              {jobs?.jobRole}
                                            </h3>
                                            <p>{jobs?.companyName}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md">
                                            <div className="mt-1 badges">
                                              {jobs?.skillsDetails?.map(
                                                (skill) => {
                                                  return (
                                                    <button
                                                      key={skill.skill}
                                                      className="m-1 badge badge-outline text-secondary fw-normal badge-pill"
                                                    >
                                                      {skill.skill}
                                                    </button>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-auto">
                                            <button
                                              onClick={() => {
                                                if (!store?.isLoggedIn) {
                                                  alert(
                                                    "You must be logged before applying this job."
                                                  );
                                                  navigate("/account-login");
                                                } else {
                                                  openInNewTab(
                                                    `/view-jobs/${jobs?.id}`
                                                  );
                                                }
                                              }}
                                              className="btn btn-primary"
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane show"
                      id="ahmedabad"
                      role="tabpanel"
                    >
                      <h4>100+ Job Openings in Ahmedabad</h4>
                      <div className="row row-deck">
                        {jobsData.map((jobs) => {
                          if (
                            jobs.jobCity?.trim().toLowerCase() === "ahmedabad"
                          ) {
                            return (
                              <div key={jobs.id} className="col-lg-4 col-md-6">
                                <div className="card my-2">
                                  <div className="row g-0">
                                    <div className="col-auto">
                                      <div className="card-body">
                                        <div className="avatar avatar-md"></div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="card-body ps-0">
                                        <div className="row">
                                          <div className="col">
                                            <h3 className="mb-0">
                                              {jobs?.jobRole}
                                            </h3>
                                            <p>{jobs?.companyName}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-md">
                                            <div className="mt-1 badges">
                                              {jobs?.skillsDetails?.map(
                                                (skill) => {
                                                  return (
                                                    <button
                                                      key={skill.skill}
                                                      className="m-1 badge badge-outline text-secondary fw-normal badge-pill"
                                                    >
                                                      {skill.skill}
                                                    </button>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-md-auto">
                                            <button
                                              onClick={() => {
                                                if (!store?.isLoggedIn) {
                                                  alert(
                                                    "You must be logged before applying this job."
                                                  );
                                                  navigate("/account-login");
                                                } else {
                                                  openInNewTab(
                                                    `/view-jobs/${jobs?.id}`
                                                  );
                                                }
                                              }}
                                              className="btn btn-primary"
                                            >
                                              Apply
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <section className="section section-secondary text-center my-5 my-md-8">
            <div className="section-header">
              <h2 className="section-title section-title-lg fs-1 m-0 p-0">
                BUILD YOUR PROFILE!
              </h2>
              <p className="section-description m-0 p-0">
                Revolutionize your hiring with our AI-powered profile builder.
              </p>
            </div>
            <div className="row my-4" data-aos-id-blocks-previews="true">
              <div className="col-md-6">
                <div className="card my-4">
                  <img
                    className="w-75"
                    src="https://careeramaze.com/wp-content/uploads/2021/01/Profile-data.gif"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 text-start">
                <div className="card my-4">
                  <div className="card-status-start bg-primary"></div>
                  <div className="card-body">
                    <h3 className="card-title">
                      <FaAngleDoubleRight fontSize={"1.2em"} className="me-2" />
                      AUTOMATED RESUME BUILDER!
                    </h3>
                    <p>
                      We build your resume to emphasize and showcase your skills
                      and achivements in such a manner, it is easy for any
                      recruiters to read and understand about you. Strong and
                      well written resume puts you in positive lights well
                      before you are shortlisted.
                    </p>
                  </div>
                </div>
                <div className="card my-4">
                  <div className="card-status-start bg-primary"></div>
                  <div className="card-body">
                    <h3 className="card-title">
                      <FaAngleDoubleRight fontSize={"1.2em"} className="me-2" />
                      AI INTERVIEW CRACKER!
                    </h3>
                    <p>
                      From your profile we create set of possible questions
                      interviewer might ask you. Preparing this questions will
                      increase your chances of getting selected by almost 60%.
                      Well prepared QnA will help you stand out from crowd.
                    </p>
                  </div>
                </div>
                <div className="card my-4">
                  <div className="card-status-start bg-primary"></div>
                  <div className="card-body">
                    <h3 className="card-title">
                      <FaAngleDoubleRight fontSize={"1.2em"} className="me-2" />
                      MOCK QUESTION PREPARATION!
                    </h3>
                    <p>
                      Knowing answers is not enough. Able to deliver it promptly
                      and handling difficult situation in interview is also
                      important. With Mock Interview preparation we help you get
                      hold on your communication and approch, preparing you for
                      live interview.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                if (!store?.isLoggedIn) {
                  alert("You must be logged in before creating profile");
                  navigate("/account-login");
                } else {
                  navigate("/resume-builder");
                }
              }}
              className="btn btn-primary w-20"
            >
              CREATE YOUR PROFILE
            </button>
          </section>
          <hr />

          <section className="section section-secondary text-center my-6 my-md-8">
            <div className="container my-10">
              <div className="section-header">
                <h2 className="section-title section-title-lg m-0 p-0">
                  GET SKILLED & BE JOB READY IN 1 WEEK.
                </h2>
                <p className="section-description m-0 p-0">
                  These are the skills that no one teaches you in academics, but
                  they are must haves to crack any interview.
                </p>
              </div>
              <div className="row my-4" data-aos-id-blocks-previews="true">
                <div className="col-md-4 my-3">
                  <div className="card card-stacked">
                    <div className="card-header">
                      <h3 className="card-title">Communication</h3>
                    </div>

                    <div className="card-body">
                      <p>description</p>
                    </div>
                    <div className="card-footer">
                      <button
                        onClick={() => navigate("/course-purchase")}
                        className="btn btn-primary w-100"
                      >
                        Take up the Course
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-3">
                  <div className="card card-stacked">
                    <div className="card-header">
                      <h3 className="card-title">Corporate Etiquette</h3>
                    </div>

                    <div className="card-body">
                      <p>description</p>
                    </div>
                    <div className="card-footer">
                      <button
                        onClick={() => navigate("/course-purchase")}
                        className="btn btn-primary w-100"
                      >
                        Take up the Course
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-3">
                  <div className="card card-stacked">
                    <div className="card-header">
                      <h3 className="card-title">Interview Prep</h3>
                    </div>

                    <div className="card-body">
                      <p>description</p>
                    </div>
                    <div className="card-footer">
                      <button
                        onClick={() => navigate("/course-purchase")}
                        className="btn btn-primary w-100"
                      >
                        Take up the Course
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr />
          <section className="section section-secondary text-center my-6 my-md-8">
            <div className="container my-10">
              <div className="section-header">
                <h2 className="section-title section-title-lg m-0 p-0">
                  SHOWCASE YOUR SKILLS TO INTERVIEWER
                </h2>
                <p className="section-description m-0 p-0">
                  Get your skill badge and certificate to shocase on your
                  profile. Makes hiring 80% faster.
                </p>
              </div>
              <div className="row my-4">
                <div className="col-md-4 my-3">
                  <div className="card card-stacked">
                    <div className="card-header">
                      <h3 className="card-title">Software Engineering</h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card my-2">
                            <div className="row g-0">
                              {testsData?.map((testdep) => {
                                if (
                                  se1 < 3 &&
                                  testdep.department === "Software Engineering"
                                ) {
                                  se1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 2,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>

                          <div className="card my-2">
                            <div className="ribbon bg-red">Advance</div>
                            <div className="row g-0">
                              {testsData?.map((testdep, index) => {
                                if (
                                  se1 > 5 &&
                                  se1 < 9 &&
                                  testdep.department === "Software Engineering"
                                ) {
                                  se1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                                if (
                                  testdep.department === "Software Engineering"
                                ) {
                                  se1++;
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-3">
                  <div className="card card-stacked">
                    <div className="card-header">
                      <h3 className="card-title">
                        Sales & Business Development
                      </h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card my-2">
                            <div className="row g-0">
                              {testsData?.map((testdep, index) => {
                                if (
                                  sbd1 < 3 &&
                                  testdep.department ===
                                    "Sales & Business Development"
                                ) {
                                  sbd1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>

                          <div className="card my-2">
                            <div className="ribbon bg-red">Advance</div>
                            <div className="row g-0">
                              {testsData?.map((testdep, index) => {
                                if (
                                  sbd1 > 5 &&
                                  sbd1 < 9 &&
                                  testdep.department ===
                                    "Sales & Business Development"
                                ) {
                                  sbd1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                                if (
                                  testdep.department ===
                                  "Sales & Business Development"
                                ) {
                                  sbd1++;
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-3">
                  <div className="card card-stacked">
                    <div className="card-header">
                      <h3 className="card-title">ADMIN & BACK OFFICE</h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card my-2">
                            <div className="row g-0">
                              {testsData?.map((testdep, index) => {
                                if (
                                  abc1 < 3 &&
                                  testdep.department === "Admin & Back Office"
                                ) {
                                  abc1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          <div className="card my-2">
                            <div className="ribbon bg-red">Advance</div>
                            <div className="row g-0">
                              {testsData?.map((testdep, index) => {
                                if (
                                  abc1 > 5 &&
                                  abc1 < 9 &&
                                  testdep.department === "Admin & Back Office"
                                ) {
                                  abc1++;
                                  return (
                                    <div key={testdep?.id} className="col-4">
                                      <div
                                        className="card-body cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/get-skill-badges/${testdep?.id}`
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            backgroundImage: `url(${testdep.icon})`,
                                          }}
                                          className="avatar avatar-lg"
                                        ></div>
                                        <p
                                          style={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {testdep.name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                                if (
                                  testdep.department === "Admin & Back Office"
                                ) {
                                  abc1++;
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  navigate("/get-skill-badges");
                }}
                className="btn btn-primary w-20"
              >
                Explore 200+ Tests
              </button>
            </div>
          </section>

          <section className="section section-secondary text-center my-10">
            <div className="row" data-aos-id-blocks-previews="true">
              <div className="col-12">
                <div className="card bg-secondary-lt">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-12">
                        <h2 className="section-title section-title-lg">
                          COMPANIES HIRED FROM US!
                        </h2>
                        <p className="section-description">
                          HIRE48 in widespread across <strong>INDIA</strong>!
                        </p>
                      </div>
                      <div className="col-lg-9 col-md-12 ">
                        <div className="btn-md-list">
                          {demoComp?.map((el) => {
                            if (el.id < 100 && el.squareLogo) {
                              return (
                                <div
                                  key={el.id}
                                  style={{
                                    backgroundImage: `url(${el.squareLogo})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                  className="m-2 avatar avatar-xl"
                                ></div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
