import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import performRequest from "../../../APIs/request";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import CandidateDetailsForShortlist from "./candidate_details_for_shortlist";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../Components/layouts/pagination";
import { setCurrentPage } from "../../../Redux/Pagination & Filters/filtersReducer";

const CandidateListing = () => {
  const [viewApplicants, setViewApplicants] = useState([]);
  const [viewDetails, setViewDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();
  const paginationStore = useSelector((store) => store.filters);
  const [page, setCurrPage] = useState(paginationStore.currentPage || 1);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();
  const handleViewApplicantList = (id) => {
    setLoading(true);
    performRequest(
      "GET",
      `/applyedcandidate/getallapplyedcandidatebyjobsid/${id}?page=${paginationStore?.currentPage}`
    )
      .then((res) => {
        if (res.status === 200) {
          setViewApplicants(res.data?.data);
          setTotalPages(Math.ceil(res?.data?.totalData / 50));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (id) {
      handleViewApplicantList(id, paginationStore?.currentPage);
    }
  }, [id, refresh, paginationStore?.currentPage]);
  useEffect(() => {
    setCurrPage(paginationStore.currentPage);
    setShowPage(Math.floor((paginationStore.currentPage - 1) / 5) * 5 + 1);
  }, [paginationStore.currentPage]);
  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="Page">
      <div className="page-wrapper">
        {!viewDetails && (
          <div className="container-xxl">
            <div className="page-header">
              <h2>All Application who apply this job</h2>
            </div>
            <div className="page-body">
              <div className="text-start">
                <div className="card">
                  {viewApplicants && viewApplicants.length > 0 ? (
                    <>
                      {" "}
                      <div className="table-responsive">
                        <table className="table table-vcenter card-table table-striped">
                          <thead>
                            <tr>
                              <th>Photo</th>
                              <th>name</th>
                              <th>education</th>
                              <th>skills</th>
                              <th>status</th>
                              <th>actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {viewApplicants?.map((el, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {" "}
                                    <img
                                      width={"100px"}
                                      src={el?.usermodel?.profilePicture}
                                      alt=""
                                      srcSet=""
                                    />
                                  </td>
                                  <td className="text-muted">
                                    {el?.usermodel?.fullName}
                                  </td>
                                  <td className="text-muted ">
                                    {el?.usermodel?.resumemodel?.education.map(
                                      (el, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="datagrid-content"
                                          >
                                            {el.educationSelect1}
                                          </div>
                                        );
                                      }
                                    )}
                                  </td>
                                  <td className="text-muted">
                                    <div className="d-flex">
                                      {el?.usermodel?.resumemodel?.skills?.map(
                                        (skills, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className=" avatar avatar-md m-1"
                                              style={{
                                                backgroundImage: `url(${skills.icon})`,
                                              }}
                                            ></div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </td>

                                  {el?.shortlistStatus && (
                                    <td>
                                      <span className="badge bg-blue-lt p-2 me-2">
                                        shortlisted by recruiter
                                      </span>
                                    </td>
                                  )}
                                  {!el?.shortlistStatus &&
                                    el?.shortlistRequest && (
                                      <td>
                                        <span className="badge bg-blue-lt p-2 me-2">
                                          shortlist request added
                                        </span>
                                      </td>
                                    )}
                                  {!el?.shortlistStatus &&
                                    !el?.shortlistRequest && (
                                      <td>
                                        <span className="badge bg-blue-lt p-2 me-2">
                                          neither shortlist request nor
                                          shortlisted
                                        </span>
                                      </td>
                                    )}

                                  <td>
                                    <button
                                      onClick={() => {
                                        setViewDetails(el);
                                      }}
                                      className="btn btn-primary mt-1"
                                    >
                                      <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 512 512"
                                        fontSize="1em"
                                        className="me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"></path>
                                      </svg>
                                      View Details
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <Pagination
                          page={page}
                          setPage={setPage}
                          totalPages={totalPages}
                          pageDatalength={viewApplicants?.length}
                          showPage={showPage}
                          setShowPage={setShowPage}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="p-4">
                      <h3>No applicant's found for this job post!</h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {viewDetails && (
          <CandidateDetailsForShortlist
            refresh={refresh}
            setRefresh={setRefresh}
            setViewDetails={setViewDetails}
            viewDetails={viewDetails}
          />
        )}
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CandidateListing;
