import React, { useEffect, useState } from "react";
import { useRef } from "react";

import {
  FaArrowCircleRight,
  FaBook,
  FaDownload,
  FaEnvelope,
  FaGraduationCap,
  FaHands,
  FaLandmark,
  FaLanguage,
  FaTrophy,
  FaUserAlt,
} from "react-icons/fa";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Footer from "../../../Components/Footer/footer";
import { FaRightToBracket } from "react-icons/fa6";
import { useParams } from "react-router";
import performRequest from "../../../APIs/request";
import { Toast } from "../../CustomComponent/toast";
import { useSelector } from "react-redux";
const cData = {
  id: 3,
  workProfile: "Software engineer",
  profilePicture:
    "https://res.cloudinary.com/dph1m5tq7/image/upload/v1721722917/hire48TestLogo/xap2l7yqsqnxud9yt0po.jpg",
  name: "Suraj Bhan Singh",
  email: "surajbhan2boaz@gmail.com",
  mobile: "8920213684",
  country: "Argentina",
  state: "Misiones",
  city: "Faridabad",
  description:
    "I'm a passionat compute scence enthusiast with a focus on full-stack development using Node.js, JavaScript, HTML, CSS, React and MongoDB. I have experience working on various projects, including web applications and websites. I believe in creating user-friendly website.",
  hobbies: "Cricket, Music and Movies",
  education: [
    {
      summary:
        "I believe in creating user-friendly and vrtunities anfree to rea\nnew opportunities and projects, so feel free to reach",
      endDate1: "2024-03",
      startDate1: "2024-05",
      university_1: "indian institute  of science",
      educationSelect1: "Primary Education",
    },
    {
      summary:
        "I admit in creating user-friendly and visuall creating I admit in crely and visually appefree ppor jkkgkjhkjh",
      endDate1: "2024-06",
      startDate1: "2024-01",
      university_1: "Nursing insitute of purne",
      educationSelect1: "Associate of Arts",
    },
  ],
  skills: [],
  workExperience: [
    {
      exitDate: "2024-02",
      location: "pune",
      position: "software enginner",
      companyName: "kryzen",
      joiningDate: "2024-01",
      locationType: "Work From Office",
      employmentType: "Full Time",
      jobDescription:
        "in creating user-friendly and vrtunities anfree to rea new opportunities and projects, so feel free to reach",
    },
  ],
  personalDetails: [
    {
      city: "Faridabadt",
      name: "Suraj Bhan Singh",
      email: "surajbhan2boaz@gmail.com",
      state: "Australian Capital Territory",
      mobile: "8920213687",
      userId: "1",
      country: "Australia",
      workProfile: "Software engineer",
      profilePicture:
        "https://res.cloudinary.com/dph1m5tq7/image/upload/v1721908953/hire48TestLogo/y4ncioblhb6tdozrmplh.jpg",
    },
  ],
  languages: [
    {
      readLanguage: ["Hindi"],
      languageSpeak: ["Punjabi"],
      languageWrite: ["Dogri"],
    },
  ],
  additionalInformation: [
    "Immediate Joinee",
    "Internship Only",
    "Available for Night Shifts",
  ],
  Projects: [],
  awardAndCertificate: [
    {
      givenBy: "Google ",
      acDescription:
        "Our AI algorithm selects the best-fit candidates for your job role, helping employers like you connect directly with candidates who have relevant experience and the desired skills.",
      awardAndCertificateName: "Google cloud certification",
    },
    {
      givenBy: "",
      acDescription: "",
      awardAndCertificateName: "",
    },
  ],
  createdAt: "2024-07-19T11:12:00.000Z",
  updatedAt: "2024-07-25T17:17:00.000Z",
  userId: "1",
  userModel: {
    fullName: "",
    userRole: "candidate",
  },
};
const CandidateDetails = () => {
  const [candidateDetails, setCandidateDetails] = useState("");
  const store = useSelector((store) => store?.userLogin);
  const [request, setRequest] = useState(false)
  

  const [skillsArray, setSkillsArray] = useState([]);
  let { id } = useParams();
  const printRef = useRef();
  const handleDownloadPdf = async () => {
    const element = printRef.current;
    if (!element) return;

    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
    });

    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };

  useEffect(() => {
    const getUserResume = (id) => {
      if (id) {
        performRequest("GET", `/resume/getresumebyid/${id}`)
          .then((res) => {
            if (res.status === 200) {
              setCandidateDetails(res.data);
              performRequest("GET", `/applyedcandidate/getsingleshortlist/${res.data.userId}`)
              .then((res) => {
                if (res.status === 200) {
                   if(res.data.shortlistBy){
                    setRequest(true)
                   }else{
                    setRequest(false)
                   }
                } else {
                  Toast("error", "Server Error");
                }
              })
              .catch((err) => {
                console.error("Error", err);
              });
            } else {
              Toast("error", "Server Error");
            }
          })
          .catch((err) => {
            console.error("Error", err);
          });
      }
    };
    if (id) {
      getUserResume(id);
    }
  }, [id]);


  


  const handleShortlist = (userId)=>{
  const data = {internalId: store?.user.UserId}
           console.log(userId, data)
           performRequest("PATCH", `/applyedcandidate/updateshortlistcandidate/${userId}`, data)
           .then((res) => {
             if (res.status === 200) {
              setRequest(true)
                console.log(res.data)
                Toast("success", "Candidate shortlisted successfully")
             } else {
               Toast("error", "Server Error");
             }
           })
           .catch((err) => {
             console.error("Error", err);
           });
  }

  const handleCancelShortlist = (userId)=>{
    const data = {internalId: ""}
             console.log(userId, data)
             performRequest("PATCH", `/applyedcandidate/updateshortlistcandidate/${userId}`, data)
             .then((res) => {
               if (res.status === 200) {
                  console.log(res.data)
                  setRequest(false)
                  Toast("success", "Cancel shortlisted successfully")
               } else {
                 Toast("error", "Server Error");
               }
             })
             .catch((err) => {
               console.error("Error", err);
             });
    }
  return (
    <>
      <div className="page">
        <div className="page-wrapper">
          <div className="container-xxl">
            <div className="page-body">
              <div className="section-header">
                <h2 className="section-title section-title-lg m-0 p-0">
                  Resume details for candidate
                </h2>
                <p className="section-description mb-4 p-0">
                  Be as much specific as you can. It will help our recruiters
                  find right talent.
                </p>
              </div>
              {candidateDetails ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      justifyItems: "center",
                    }}
                  >
                    <div
                      ref={printRef}
                      style={{ maxWidth: "800px", minWidth: "800px" }}
                    >
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div
                              className="col-md-4 card bg-secondary-lt p-2"
                              style={{ minHeight: "80vh" }}
                            >
                              <div>
                                <p className="text-center m-0 pb-2">
                                  {candidateDetails?.personalDetails?.[0] && (
                                    <img
                                      src={
                                        candidateDetails?.personalDetails?.[0]
                                          ?.profilePicture
                                      }
                                      alt="User Image"
                                      srcSet=""
                                    />
                                  )}
                                </p>
                              </div>

                              <p className="fs-5 m-0">
                                {candidateDetails.personalDetails?.[0]
                                  ?.email && (
                                  <FaEnvelope
                                    fontSize={"1.2em"}
                                    className="me-2"
                                  />
                                )}
                                {candidateDetails?.personalDetails?.[0]
                                  ?.email &&
                                  candidateDetails?.personalDetails?.[0]?.email}
                              </p>
                              <p className="fs-5 m-0">
                                {candidateDetails?.personalDetails?.[0]
                                  ?.mobile &&
                                  candidateDetails?.personalDetails?.[0]
                                    ?.mobile}
                              </p>
                              <p className="fs-5 m-0">
                                {candidateDetails?.personalDetails?.[0]
                                  ?.country &&
                                  candidateDetails?.personalDetails?.[0]
                                    ?.country}
                              </p>
                              <p className="fs-5 m-0">
                                {candidateDetails?.personalDetails?.[0]?.city &&
                                  candidateDetails?.personalDetails?.[0]?.city}
                                {candidateDetails?.personalDetails?.[0]?.city &&
                                  ","}
                                {candidateDetails?.personalDetails?.[0]
                                  ?.state &&
                                  candidateDetails?.personalDetails?.[0]?.state}
                              </p>

                              {candidateDetails?.education &&
                                candidateDetails?.education.length > 0 &&
                                Object.values(
                                  candidateDetails?.education?.[0]
                                ).some((value) => value !== "") && (
                                  <>
                                    <hr className="p-0 my-2" />
                                    <p className="p-0 mb-2 fs-3 fw-bold">
                                      <FaGraduationCap
                                        fontSize={"1em"}
                                        className="me-1"
                                      />
                                      Education
                                    </p>
                                  </>
                                )}

                              <>
                                {candidateDetails?.education &&
                                  candidateDetails?.education?.map(
                                    (el, index) => (
                                      <div key={index}>
                                        <h4 className="p-0 m-0 fs-4">
                                          {el.educationSelect1}
                                        </h4>
                                        <p className="p-0 m-0 fs-5">
                                          {el.university_1}
                                        </p>
                                        <p className="fs-5">
                                          {el.startDate1}
                                          <span>
                                            {el.endDate1 && "To"}
                                            {el.endDate1}
                                          </span>
                                        </p>
                                        <p className="fs-5">{el.summary}</p>
                                      </div>
                                    )
                                  )}
                              </>

                              {candidateDetails && candidateDetails.skills?.length > 0 && (
                                <>
                                  <hr className="p-0 my-2" />
                                  <p className="p-0 mb-2 fs-3 fw-bold">
                                    <FaBook fontSize={"1em"} className="me-1" />{" "}
                                    Skills
                                  </p>
                                  <p className="m-0 p-0">
                                  {candidateDetails.skills?.map((skills, index) => {
                                    return (
                                      <div
                                      key={index}
                                        className="avatar avatar-md m-1"
                                        style={{
                                          backgroundImage: `url(${skills.icon})`,
                                        }}
                                      ></div>
                                    );
                                  })}
                                  </p>
                                  <hr className="p-0 my-2" />
                                </>
                              )}
                              {candidateDetails?.languages &&
                                candidateDetails?.languages.length > 0 &&
                                Object.values(
                                  candidateDetails?.languages?.[0]
                                ).some((arr) => arr.length > 0) && (
                                  <>
                                    <>
                                      <p className="p-0 mb-2 fs-3 fw-bold">
                                        <FaLanguage
                                          fontSize={"1em"}
                                          className="me-1"
                                        />{" "}
                                        Languages
                                      </p>

                                      {candidateDetails?.languages &&
                                        candidateDetails?.languages?.[0]
                                          .languageSpeak?.length > 0 && (
                                          <>
                                            <p className="p-0 m-0 fs-4 fw-bold">
                                              Speak:
                                            </p>
                                            <p className="p-0 m-0">
                                              {candidateDetails?.languages?.[0]?.languageSpeak?.map(
                                                (el) => (
                                                  <span
                                                    className="badge badge-outline text-green me-1"
                                                    key={el}
                                                  >
                                                    {el}
                                                  </span>
                                                )
                                              )}
                                            </p>
                                          </>
                                        )}

                                      {candidateDetails?.languages &&
                                        candidateDetails?.languages?.[0]
                                          ?.readLanguage?.length > 0 && (
                                          <>
                                            <p className="p-0 m-0 fs-4 fw-bold">
                                              Read:
                                            </p>
                                            <p className="p-0 m-0">
                                              {candidateDetails?.languages?.[0]?.readLanguage.map(
                                                (el) => (
                                                  <span
                                                    className="badge badge-outline text-green me-1"
                                                    key={el}
                                                  >
                                                    {el}
                                                  </span>
                                                )
                                              )}
                                            </p>
                                          </>
                                        )}

                                      {candidateDetails.languages?.[0]
                                        ?.languageWrite.length > 0 && (
                                        <>
                                          <p className="p-0 m-0 fs-4 fw-bold">
                                            Write:
                                          </p>
                                          <p className="p-0 m-0">
                                            {candidateDetails.languages?.[0]
                                              ?.languageWrite.length > 0 ? (
                                              candidateDetails.languages?.[0]?.languageWrite.map(
                                                (el) => (
                                                  <span
                                                    className="badge badge-outline text-green me-1"
                                                    key={el}
                                                  >
                                                    {el}
                                                  </span>
                                                )
                                              )
                                            ) : (
                                              <></>
                                            )}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  </>
                                )}

                              {candidateDetails.hobbies && (
                                <>
                                  <hr className="p-0 my-2" />
                                  <p className="p-0 mb-2 fs-3 fw-bold">
                                    <FaHands
                                      fontSize={"1em"}
                                      className="me-1"
                                    />
                                    Hobbies
                                  </p>
                                  <p>{candidateDetails?.hobbies}</p>
                                </>
                              )}
                            </div>
                            <div className="col-md-8">
                              <h1 className="p-0 m-0 fs-1">
                                {candidateDetails?.personalDetails?.[0] &&
                                  candidateDetails?.personalDetails?.[0]?.name}
                              </h1>

                              {candidateDetails?.personalDetails &&
                                candidateDetails?.personalDetails?.[0]
                                  ?.workProfile && (
                                  <>
                                    <p className="p-0 m-0 fs-4">
                                      <FaArrowCircleRight
                                        fontSize={"1em"}
                                        className="me-1"
                                      />{" "}
                                      <span>
                                        {" "}
                                        {candidateDetails?.personalDetails &&
                                          candidateDetails?.personalDetails?.[0]
                                            ?.workProfile}
                                      </span>
                                    </p>
                                  </>
                                )}

                              {candidateDetails?.additionalInformation &&
                                candidateDetails?.additionalInformation?.map(
                                  (el, index) => {
                                    return (
                                      <>
                                        <span className="badge badge-outline text-green my-1 me-1">
                                          {el}
                                        </span>
                                      </>
                                    );
                                  }
                                )}

                              {candidateDetails.description && (
                                <>
                                  <hr className="p-0 my-2" />
                                  <p className="p-0 m-0 fs-4 fw-bold">
                                    <FaLandmark
                                      fontSize={"1em"}
                                      className="me-1"
                                    />
                                    Professional Summary
                                  </p>
                                </>
                              )}

                              <p className="fs-5 ps-3">
                                {candidateDetails.description &&
                                  candidateDetails.description}
                              </p>
                              <hr className="p-0 my-2" />
                              {candidateDetails?.workExperience &&
                                candidateDetails?.workExperience.length > 0 &&
                                Object.values(
                                  candidateDetails?.workExperience?.[0]
                                ).some((value) => value !== "") && (
                                  <p className="p-0 m-0 fs-4 fw-bold">
                                    <FaUserAlt
                                      fontSize={"1em"}
                                      className="me-1"
                                    />
                                    Professional Experience
                                  </p>
                                )}

                              <div className="ps-3">
                                <>
                                  {candidateDetails?.workExperience &&
                                    candidateDetails?.workExperience.map(
                                      (el) => (
                                        <div key={el.position + el.companyName}>
                                          <p className="p-0 m-0 fs-4 fw-bold">
                                            {el.position}
                                          </p>
                                          <p className="p-0 m-0 fs-5">
                                            {el.companyName} <br />
                                            {el.location} <br />
                                            {el.locationType} <br />
                                            {el.joiningDate}{" "}
                                            {el.exitDate && "To"} {el.exitDate}
                                          </p>
                                          <p className="fs-5 pt-2">
                                            {el.jobDescription}
                                          </p>
                                        </div>
                                      )
                                    )}
                                </>
                              </div>

                              {candidateDetails?.awardAndCertificate &&
                                candidateDetails?.awardAndCertificate.length >
                                  0 &&
                                Object.values(
                                  candidateDetails?.awardAndCertificate?.[0]
                                ).some((value) => value !== "") && (
                                  <>
                                    <hr className="p-0 my-2" />
                                    <p className="p-0 m-0 fs-4 fw-bold">
                                      <FaTrophy
                                        fontSize={"1em"}
                                        className="me-1"
                                      />
                                      Awards & Certificates
                                    </p>
                                  </>
                                )}

                              <div className="ps-2">
                                <>
                                  {candidateDetails?.awardAndCertificate &&
                                    candidateDetails?.awardAndCertificate?.map(
                                      (el, index) => (
                                        <div key={index}>
                                          <p className="p-0 m-0 fs-4 fw-bold">
                                            {el.awardAndCertificateName}
                                          </p>
                                          <p className="p-0 m-0 fs-5">
                                            {el.givenBy}
                                          </p>
                                          <p className="fs-5 pt-2">
                                            {el.acDescription}
                                          </p>
                                        </div>
                                      )
                                    )}
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-center">
                    <button
                      className="btn  btn-primary m-1"
                      onClick={handleDownloadPdf}
                    >
                      <FaDownload fontSize={"1em"} className="me-2" /> Download
                      Resume
                    </button>
                  {request?(
                      <button
                      className="btn  btn-primary m-1"
                      onClick={()=>{handleCancelShortlist(candidateDetails?.userId)}}
                    >
                      <FaRightToBracket fontSize={"1em"} className="me-2" />{" "}
                       Cancel Shortlist Candidate
                    </button>
                  ):(
                    <button
                    className="btn  btn-primary m-1"
                    onClick={()=>{handleShortlist(candidateDetails?.userId)}}
                  >
                    <FaRightToBracket fontSize={"1em"} className="me-2" />{" "}
                     Shortlist  Candidate
                  </button>
                  )}
                  </div>
                </>
              ) : (
                <div className="page-body">
                  <h3>Resume details not available for this candidate</h3>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CandidateDetails;
