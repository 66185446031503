import React, { useEffect, useState } from "react";
import FormSubmitButton from "../../../Components/layouts/formSubmitButton";
import SimpleButton from "../../../Components/layouts/simple_button";
import { Form, Formik } from "formik";
import { Toast } from "../../CustomComponent/toast";
import LoadingPage from "../../../Components/layouts/loading";
import { JobsInfo } from "../../../Schema Info/info";
import CustomSelect from "../../CustomComponent/custom_select";
import CustomInput from "../../CustomComponent/custom_input";
import CustomTextArea from "../../CustomComponent/custom_textarea";
import Footer from "../../../Components/Footer/footer";
import performRequest from "../../../APIs/request";
import { useNavigate, useParams } from "react-router";
import { JobsSchema } from "../../../Schemas/Jobs/jobs_schema";
import { departmentsInfo } from "../../../Schema Info/departmentsInfo";
import CustomCheckbox from "../../CustomComponent/custom_checkbox";
import { useSelector } from "react-redux";
import { recruiter, superAdmin } from "../../../Config/config";
const AddNewJobs = () => {
  const store = useSelector((store) => store?.userLogin);
  const [jobsInfo, setJobsInfo] = useState(JobsInfo);
  const [loading, setLoading] = useState(false);
  const [employerList, setEmployerList] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    performRequest("GET", "/employer/getallemployerscompanynameandid")
      .then((res) => {
        if (res.status === 200) {
          setEmployerList(res.data);
        }
      })
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    if (id) {
      setLoading(true);
      performRequest("GET", `/jobs/getjobbyid/${id}`)
        .then((res) => {
          setJobsInfo(res?.data);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [id]);
  const handelSubmit = (values, actions) => {
    values = { ...values, numberOfPositions: +values.numberOfPositions || 1 };
    if (id) {
      performRequest("PATCH", `/jobs/updatejob/${id}`, values)
        .then((res) => {
          if (res.status === 200) {
            Toast("success", "Job Updated  Successfully");
            navigate(`/job-details/${res.data.id}`);
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          Toast("error", err?.response?.data?.flag);
          console.error("Error", err);
        });
    } else {
      values = { ...values, userId: store?.user?.UserId };
      performRequest("POST", "/jobs/createjob", values)
        .then((res) => {
          if (res.status === 201) {
            Toast("success", "Job Created Successfully");
            navigate(`/job-details/${res.data.id}`);
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          Toast("error", err?.response?.data?.flag);
          console.error("Error", err);
        });
    }
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xxl">
          <div className="page-body">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
                CREATE A JOB POST FOR ORGANIZATION NAME
              </h2>
              <p className="section-description mb-4 p-0">
                Be as much specific as you can. It will help our recruiters find
                right talent.
              </p>
            </div>
          </div>

          <div className="card">
            {jobsInfo && (
              <Formik
                initialValues={jobsInfo}
                validationSchema={JobsSchema}
                onSubmit={handelSubmit}
              >
                {(props) => (
                  <Form>
                    <div className="row p-2">
                      <div className="col-lg-6 col-md-6 col-sm-12 ">
                        <div className="p-2">
                          <div className="card bg-primary-lt my-1">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                STEP 1: ADD PROFILE DETAILS
                              </h3>
                              <hr className="p-0 my-2" />
                              {(store?.user?.Role === recruiter ||
                                store?.user?.Role === superAdmin) && (
                                <div className="row mb-2">
                                  <label className="form-label col-3">
                                    Employer:
                                  </label>
                                  <div className="w-100 col">
                                    <CustomSelect name="employerId" type="text">
                                      <option value="">Select employer</option>
                                      {employerList?.map((el) => (
                                        <option key={el.id} value={el.id}>
                                          {el.companyName}
                                        </option>
                                      ))}
                                    </CustomSelect>

                                    {props.touched.employerId &&
                                      props.errors.employerId && (
                                        <p className="text-danger">
                                          {props.errors.employerId}
                                        </p>
                                      )}
                                  </div>
                                </div>
                              )}
                              <div className="row mb-2">
                                <label className="form-label col-3">
                                  Job Profile:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput name="companyName" type="text" />
                                  {props.touched.companyName &&
                                    props.errors.companyName && (
                                      <p className="text-danger">
                                        {props.errors.companyName}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="row mb-2">
                                <label className="w-25 form-label col-">
                                  Department:
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect
                                    type="text"
                                    name="jobDepartment"
                                  >
                                    <option value="">Select</option>
                                    {departmentsInfo?.map((dep, index) => {
                                      return (
                                        <option key={index} value={dep.dep}>
                                          {dep.dep}
                                        </option>
                                      );
                                    })}
                                  </CustomSelect>
                                  {props.touched.jobDepartment &&
                                    props.errors.jobDepartment && (
                                      <p className="text-danger">
                                        {props.errors.jobDepartment}
                                      </p>
                                    )}
                                </div>
                              </div>
                              {props.values.jobDepartment && (
                                <div className="row mb-2">
                                  <label className="w-25 form-label col-3">
                                    Job Category:
                                  </label>
                                  <div className="w-100 col">
                                    <CustomSelect type="text" name="jobRole">
                                      <option value="">Select</option>
                                      {departmentsInfo
                                        ?.filter(
                                          (dep) =>
                                            dep.dep ===
                                            props.values.jobDepartment
                                        )
                                        ?.map((dep) => {
                                          return dep.roles.map(
                                            (role, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={role}
                                                >
                                                  {role}
                                                </option>
                                              );
                                            }
                                          );
                                        })}
                                    </CustomSelect>
                                    {props.touched.jobRole &&
                                      props.errors.jobRole && (
                                        <p className="text-danger">
                                          {props.errors.jobRole}
                                        </p>
                                      )}
                                  </div>
                                </div>
                              )}
                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Job Duration:
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect type="text" name="workType">
                                    <option value="">Select</option>
                                    <option value="Full time">Full time</option>
                                    <option value="Part time">Part time</option>
                                    <option value="Both">Both</option>
                                  </CustomSelect>
                                  {props.touched.workType &&
                                    props.errors.workType && (
                                      <p className="text-danger">
                                        {props.errors.workType}
                                      </p>
                                    )}
                                </div>
                              </div>

                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Shift Type:
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect type="text" name="workShift">
                                    <option value="">Select</option>
                                    <option value="Day shift">Day shift</option>
                                    <option value="Night shift">
                                      Night shift
                                    </option>
                                  </CustomSelect>
                                  {props.touched.workShift &&
                                    props.errors.workShift && (
                                      <p className="text-danger">
                                        {props.errors.workShift}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card bg-primary-lt my-1">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                STEP 2: ADD WORKPLACE DETAILS
                              </h3>
                              <hr className="p-0 my-2" />
                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Joining Address:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput name="jobLocation" type="text" />
                                  {props.touched.jobLocation &&
                                    props.errors.jobLocation && (
                                      <p className="text-danger">
                                        {props.errors.jobLocation}
                                      </p>
                                    )}
                                </div>
                              </div>

                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Joining City:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput name="jobCity" type="text" />
                                  {props.touched.jobCity &&
                                    props.errors.jobCity && (
                                      <p className="text-danger">
                                        {props.errors.jobCity}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Joining State:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput name="jobState" type="text" />
                                  {props.touched.jobState &&
                                    props.errors.jobState && (
                                      <p className="text-danger">
                                        {props.errors.jobState}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card bg-primary-lt my-1">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                STEP 3: ADD SALARY DETAILS
                              </h3>
                              <hr className="p-0 my-2" />
                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Salary Structure:
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect name="payType" type="text">
                                    <option value="">Select</option>
                                    <option value="Fixed Only">
                                      Fixed Only
                                    </option>
                                    <option value="Fixed + Incentive">
                                      Fixed + Incentive
                                    </option>
                                    <option value="Incentive Only">
                                      Incentive Only
                                    </option>
                                  </CustomSelect>
                                  {props.touched.payType &&
                                    props.errors.payType && (
                                      <p className="text-danger">
                                        {props.errors.payType}
                                      </p>
                                    )}
                                </div>
                              </div>
                            {(props.values.payType === "Incentive Only")?(<></>):(
                                <div className="mb-3 row">
                                <label className="w-25 form-label col-3">
                                  Fixed Salary:
                                </label>
                                <div className="w-50 col">
                                  <CustomInput
                                    name="salaryPerMonthMin"
                                    type="text"
                                    placeholder="Min Salary"
                                  />
                                  {props.touched.salaryPerMonthMin &&
                                    props.errors.salaryPerMonthMin && (
                                      <p className="text-danger">
                                        {props.errors.salaryPerMonthMin}
                                      </p>
                                    )}
                                </div>
                                <div className="w-100 col">
                                  <CustomInput
                                    name="salaryPerMonthMax"
                                    type="text"
                                    placeholder="Max Salary"
                                  />
                                  {props.touched.salaryPerMonthMax &&
                                    props.errors.salaryPerMonthMax && (
                                      <p className="text-danger">
                                        {props.errors.salaryPerMonthMax}
                                      </p>
                                    )}
                                </div>
                              </div>
                            )}

                              {(props.values.payType==="Fixed Only")?(<></>):(
                                <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Avg Incentive/Mo:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput
                                    name="incetivePerMonth"
                                    type="text"
                                    placeholder="Over the fixed salary"
                                  />
                                  {props.touched.incetivePerMonth &&
                                    props.errors.incetivePerMonth && (
                                      <p className="text-danger">
                                        {props.errors.incetivePerMonth}
                                      </p>
                                    )}
                                </div>
                              </div>
                              )}

                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Additional Perks:
                                </label>
                                <div className="col">
                                  <div className="row">
                                    <div className="col-6">
                                      <label className="form-check form-check-inline">
                                        <CustomCheckbox
                                          name="additionalPerks"
                                          type="checkbox"
                                          value="Flexible working hours"
                                        />
                                        <span className="form-check-label">
                                          Flexible working hours
                                        </span>
                                      </label>
                                      <label className="form-check form-check-inline">
                                        <CustomCheckbox
                                          name="additionalPerks"
                                          type="checkbox"
                                          value="Weekly payout"
                                        />
                                        <span className="form-check-label">
                                          Weekly payout
                                        </span>
                                      </label>
                                      <label className="form-check form-check-inline">
                                        <CustomCheckbox
                                          name="additionalPerks"
                                          type="checkbox"
                                          value="Overtime pay"
                                        />
                                        <span className="form-check-label">
                                          Overtime pay
                                        </span>
                                      </label>
                                      <label className="form-check form-check-inline">
                                        <CustomCheckbox
                                          name="additionalPerks"
                                          type="checkbox"
                                          value="Anual bonus"
                                        />
                                        <span className="form-check-label">
                                          Anual bonus
                                        </span>
                                      </label>
                                    </div>
                                    <div className="col-6">
                                      <label className="form-check form-check-inline">
                                        <CustomCheckbox
                                          name="additionalPerks"
                                          type="checkbox"
                                          value="PF"
                                        />
                                        <span className="form-check-label">
                                          Provident fund
                                        </span>
                                      </label>
                                      <label className="form-check form-check-inline">
                                        <CustomCheckbox
                                          name="additionalPerks"
                                          type="checkbox"
                                          value="Travel Allowance"
                                        />
                                        <span className="form-check-label">
                                          Travel allowance
                                        </span>
                                      </label>
                                      <label className="form-check form-check-inline">
                                        <CustomCheckbox
                                          name="additionalPerks"
                                          type="checkbox"
                                          value="Laptop"
                                        />
                                        <span className="form-check-label">
                                          Laptop
                                        </span>
                                      </label>
                                      <label className="form-check form-check-inline">
                                        <CustomCheckbox
                                          name="additionalPerks"
                                          type="checkbox"
                                          value="Health Insurance"
                                        />
                                        <span className="form-check-label">
                                          Health insurance
                                        </span>
                                      </label>
                                      {props.touched.additionalPerks &&
                                        props.errors.additionalPerks && (
                                          <p className="text-danger">
                                            {props.errors.additionalPerks}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="p-2">
                          <div className="card bg-primary-lt my-1">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                STEP 4: ADD JOBROLL DETAILS
                              </h3>
                              <hr className="p-0 my-2" />
                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Min. Education:
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect
                                    name="minimumEducation"
                                    type="text"
                                  >
                                    <option value="">select</option>
                                    <option value="10th Or Below 10th">
                                      10th Or Below 10th
                                    </option>
                                    <option value="12th Pass">12th Pass</option>
                                    <option value="Diploma">Diploma</option>
                                    <option value="ITI">ITI</option>
                                    <option value="Graduate">Graduate</option>
                                    <option value="Post Graduate">
                                      Post Graduate
                                    </option>
                                  </CustomSelect>
                                  {props.touched.minimumEducation &&
                                    props.errors.minimumEducation && (
                                      <p className="text-danger">
                                        {props.errors.minimumEducation}
                                      </p>
                                    )}
                                </div>
                              </div>

                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  English Level:
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect name="englishLevel" type="text">
                                    <option value=""></option>
                                    <option value="No English">
                                      No English
                                    </option>
                                    <option value="Basic English">
                                      Basic English
                                    </option>
                                    <option value="Good English">
                                      Good English
                                    </option>
                                  </CustomSelect>
                                  {props.touched.englishLevel &&
                                    props.errors.englishLevel && (
                                      <p className="text-danger">
                                        {props.errors.englishLevel}
                                      </p>
                                    )}
                                </div>
                              </div>

                              <div className="mb-3 row">
                                <label className="w-25 form-label col-3">
                                  Required Exp.:
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect
                                    name="experienceRequired"
                                    type="text"
                                  >
                                    <option value="">Select</option>
                                    <option value="Any">Any</option>
                                    <option value="Experienced Only">
                                      Experienced Only
                                    </option>
                                    <option value="Fresher Only">
                                      Fresher Only
                                    </option>
                                  </CustomSelect>
                                  {props.touched.experienceRequired &&
                                    props.errors.experienceRequired && (
                                      <p className="text-danger">
                                        {props.errors.experienceRequired}
                                      </p>
                                    )}
                                </div>
                              </div>

                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Job Description:
                                </label>
                                <div className="w-100 col">
                                  <CustomTextArea
                                    name="JobDescription"
                                    type="text"
                                    rows={9}
                                  />

                                  {props.touched.JobDescription &&
                                    props.errors.JobDescription && (
                                      <p className="text-danger">
                                        {props.errors.JobDescription}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Joining Date:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput
                                    name="receiveApplicationFrom"
                                    type="date"
                                  />
                                  {props.touched.receiveApplicationFrom &&
                                    props.errors.receiveApplicationFrom && (
                                      <p className="text-danger">
                                        {props.errors.receiveApplicationFrom}
                                      </p>
                                    )}
                                </div>
                              </div>

                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Job Type:
                                </label>
                                <div className="w-100 col">
                                  <CustomSelect name="jobType" type="text">
                                    <option value="">Select</option>
                                    <option value="Work From Home">
                                      Work From Home
                                    </option>
                                    <option value="Work From Home">
                                      Work From Office
                                    </option>
                                    <option value="Hybrid Work">
                                      Hybrid Work
                                    </option>
                                  </CustomSelect>

                                  {props.touched.jobType &&
                                    props.errors.jobType && (
                                      <p className="text-danger">
                                        {props.errors.jobType}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Job Positions:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput
                                    name="numberOfPositions"
                                    type="text"
                                  />
                                  {props.touched.numberOfPositions &&
                                    props.errors.numberOfPositions && (
                                      <p className="text-danger">
                                        {props.errors.numberOfPositions}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*
  <div className="mb-2 row">
                              <label className="w-25 form-label col-3">
                                Skills Required:
                              </label>
                              <div className="w-100 col">
                                <FieldArray name="skillsDetails">
                                  {({ push, remove }) => {
                                    return (
                                      <div>
                                        {props.values.skillsDetails &&
                                          props.values.skillsDetails.map(
                                            (skill, index) => (
                                              <div
                                                key={index}
                                                className="row g-3 align-items-center"
                                              >
                                                <div className="col-lg">
                                                  <div className="w-100 col">
                                                    <CustomInput
                                                      type="text"
                                                      placeholder={
                                                        "Select a Skill"
                                                      }
                                                      name={`skillsDetails[${index}].skill`}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg">
                                                 
                                                  <div className="w-100 col">
                                                    <CustomInput
                                                      type="text"
                                                      placeholder={
                                                        "required percentage"
                                                      }
                                                      name={`skillsDetails[${index}].minReqPercentage`}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="col-lg mt-3 ">
                                                  <button
                                                    className="col-3 btn btn-primary"
                                                    style={{
                                                      width: "70px",
                                                      height: "30px",
                                                    }}
                                                    type="button"
                                                    onClick={() => {
                                                      remove(index);
                                                    }}
                                                  >
                                                    Remove
                                                  </button>
                                                </div>
                                                <hr className="m-1" />
                                              </div>
                                            )
                                          )}
                                        <button
                                          className="col-3 btn btn-primary"
                                          style={{
                                            width: "100px",
                                            height: "30px",
                                          }}
                                          type="button"
                                          onClick={() => {
                                            push(skillInfo);
                                          }}
                                        >
                                          Add More
                                        </button>
                                      </div>
                                    );
                                  }}
                                </FieldArray>
                              </div>
                            </div>
*/}
                          <div className="card bg-primary-lt my-1">
                            <div className="card-body">
                              <h3 className="card-title m-0 p-0">
                                STEP 5: ADD INTERVIEW DETAILS
                              </h3>
                              <hr className="p-0 my-2" />

                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Interview Address:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput
                                    name="interviewAddress"
                                    type="text"
                                    placeholder="For In-person Interview"
                                  />

                                  {props.touched.interviewAddress &&
                                    props.errors.interviewAddress && (
                                      <p className="text-danger">
                                        {props.errors.interviewAddress}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Interview Period:
                                </label>
                                <div className="w-100 col">
                                  <CustomInput
                                    name="interviewStartDate"
                                    type="date"
                                  />

                                  {props.touched.interviewStartDate &&
                                    props.errors.interviewStartDate && (
                                      <p className="text-danger">
                                        {props.errors.interviewStartDate}
                                      </p>
                                    )}
                                </div>
                                <div className="w-100 col">
                                  <CustomInput
                                    name="interviewEndDate"
                                    type="date"
                                  />

                                  {props.touched.interviewEndDate &&
                                    props.errors.interviewEndDate && (
                                      <p className="text-danger">
                                        {props.errors.interviewEndDate}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-2 row">
                                <label className="w-25 form-label col-3">
                                  Other Instructions:
                                </label>
                                <div className="w-100 col">
                                  <CustomTextArea
                                    name="otherInstructions"
                                    type="text"
                                    placeholder="Any specific requirement as per your industry."
                                    rows={4}
                                  />

                                  {props.touched.otherInstructions &&
                                    props.errors.otherInstructions && (
                                      <p className="text-danger">
                                        {props.errors.otherInstructions}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="btn-list justify-content-end">
                        <FormSubmitButton
                          buttonText={
                            id ? "Update Job Post" : "Create Job Post"
                          }
                        />
                        <SimpleButton buttonText="Cancel" type="secondary" />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default AddNewJobs;
