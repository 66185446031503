import React, { useState } from "react";

import { Form, Formik } from "formik";
import kryzenLogo from "../../assets/hire48logoHorizontal.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../Components/layouts/loading";

import CustomInput from "../CustomComponent/custom_input";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import { UserSignupSchema } from "../../Schemas/User/user_login_schema";
import performRequest from "../../APIs/request";
import { Toast } from "../CustomComponent/toast";
import { login } from "../../Redux/User/login_reducer";

const AccountSignup = () => {
  const store = useSelector((store) => store.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const showPassword = () => {
    setShowPass(!showPass);
  };
  const handelSubmit = async (values, actions) => {
    values = { ...values, userRole: "candidate" };
    setLoading(true);
    performRequest("POST", "/auth/register", values)
      .then((res) => {
        if (res.status === 201) {
          Toast("success", "Registration successful!");
          dispatch(login(res.data));
          navigate("/");
        } else if (res.status === 200) {
          Toast("error", "User already exists! Please login.");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page page-center">
      <div className="container container-tight py-4">
        <div className="text-center mt-4 mb-4">
          <img src={kryzenLogo} height="100" alt="" />
        </div>
        <div className="card card-md">
          <div className="card-body">
            <h2 className="text-center mb-1">Welcome to Hire48.</h2>
            <p className="mb-3 text-center">
              Let's revamp the future together!
            </p>
            <Formik
              initialValues={{
                email: "",
                password: "",
                fullName: "",
                contactNumber: "",
              }}
              validationSchema={UserSignupSchema}
              onSubmit={handelSubmit}
            >
              {(props) => (
                <Form id="k-user-login-form">
                  <div className="mb-3">
                    <label className="form-label">Full Name</label>
                    <CustomInput
                      type="text"
                      name="fullName"
                      placeholder="full name"
                      autoComplete="off"
                    />

                    {props.touched.fullName && props.errors.fullName && (
                      <p className="text-danger">{props.errors.fullName}</p>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Mobile</label>
                    <CustomInput
                      type="text"
                      name="contactNumber"
                      placeholder="xxxxxxxxxx"
                      autoComplete="off"
                    />

                    {props.touched.contactNumber &&
                      props.errors.contactNumber && (
                        <p className="text-danger">
                          {props.errors.contactNumber}
                        </p>
                      )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <CustomInput
                      type="email"
                      name="email"
                      placeholder="your@email.com"
                      autoComplete="off"
                    />

                    {props.touched.email && props.errors.email && (
                      <p className="text-danger">{props.errors.email}</p>
                    )}
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Password</label>
                    <div className="input-group input-group-flat">
                      <CustomInput
                        name="password"
                        autoComplete="off"
                        placeholder="Enter password"
                        type={showPass ? "text" : "password"}
                      />

                      <span className="input-group-text">
                        <span
                          onClick={showPassword}
                          className="link-secondary"
                          title="Show password"
                          role="button"
                          data-bs-toggle="tooltip"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                            <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    {props.touched.password && props.errors.password && (
                      <p className="text-danger">{props.errors.password}</p>
                    )}
                  </div>
                  <div className="mb-2">
                    <label className="form-check">
                      <input type="checkbox" className="form-check-input" />
                      <span className="form-check-label">Remember me</span>
                    </label>
                  </div>
                  <div className="form-footer">
                    <FormSubmitButton buttonText={"Sign up"} width={"full"} />
                  </div>
                  <div className="my-3 mx-2">
                    <span>
                      <a href="/forgot-password">Forgot your password?</a>
                    </span>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* <p className="p-0 my-1 text-center">
          &copy; 2019-2024 Kryzen Biotech Private Limited.
        </p> */}
      </div>
    </div>
  );
};

export default AccountSignup;
