import { Form, Formik } from "formik";
import React from "react";
import CustomInput from "../../Pages/CustomComponent/custom_input";
import { BookDemoInfo } from "../../Schema Info/info";
import { BookDemoSchema } from "../../Schemas/Demo/bookDemo_schema";
import FormSubmitButton from "../layouts/formSubmitButton";
import performRequest from "../../APIs/request";
import { candidate } from "../../Config/config";
import { useSelector } from "react-redux";

const FooterTop = () => {
  const store = useSelector((store) => store?.userLogin?.user);
  const handelDemoSubmit = (values) => {
    performRequest("POST", "bookdemo/createbookdemo", values)
      .then((res) => {
        if (res.status === 201) {
          alert("Your Demo request was successfully upated.");
          document.getElementById("close-modal").click();
        }
      })
      .catch((err) => console.error(err));
  };
  return (
    <>
      <div
        className=" modal modal-blur fade"
        id="modal-team"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className=" modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content bg-primary-lt">
            <div className="modal-header">
              <h2 className="mt-3 ">Book Demo</h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <Formik
              initialValues={BookDemoInfo}
              validationSchema={BookDemoSchema}
              onSubmit={handelDemoSubmit}
            >
              {(props) => (
                <Form>
                  <div className="modal-body bg-primary-lt">
                    <div className="mb-3">
                      <label className="form-label required">Full Name</label>
                      <CustomInput name="fullName" type={"text"} />
                      {props.touched.fullName && props.errors.fullName && (
                        <p className="text-danger">{props.errors.fullName}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label required">
                        Company Name
                      </label>
                      <CustomInput name="companyName" />
                      {props.touched.companyName &&
                        props.errors.companyName && (
                          <p className="text-danger">
                            {props.errors.companyName}
                          </p>
                        )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label required">
                        Bussiness Email
                      </label>
                      <CustomInput name="companyEmail" type="email" />
                      {props.touched.companyEmail &&
                        props.errors.companyEmail && (
                          <p className="text-danger">
                            {props.errors.companyEmail}
                          </p>
                        )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label required">
                        Contact Number
                      </label>
                      <CustomInput name="contactNumber" type="text" />
                      {props.touched.contactNumber &&
                        props.errors.contactNumber && (
                          <p className="text-danger">
                            {props.errors.contactNumber}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="modal-footer bg-primary-lt">
                    <button
                      type="button"
                      className="btn me-auto"
                      data-bs-dismiss="modal"
                      id="close-modal"
                    >
                      Close
                    </button>
                    <FormSubmitButton buttonText={"Book Demo"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="page-body  border-2 border-y pt-5 pb-5 border-secondary">
        <div className="container-xl ">
          {store?.Role !== candidate && (
            <div className="card card-md">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-md-9 col-lg-9">
                    <h2 className="h1 fs-1">
                      Get Your Dedicated Hiring Agency
                    </h2>
                    <p className="m-0 text-secondary fs-3">
                      Hire48 app has over 5 Crore active job seekers across 70+
                      job categories. Our AI algorithm selects the best-fit
                      candidates for your job role, helping employers like you
                      connect directly with candidates who have relevant
                      experience and the desired skills.
                    </p>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <button
                      className="btn w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-team"
                    >
                      Book A Demo
                    </button>
                    <p className="m-0 text-secondary text-center">
                      09:00AM to 06:00PM All Days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {store?.Role !== candidate && <hr />}
          {/* <div className="row row-cards mt-3  ps-7 ">
                        <div className="col-md-6 col-lg-3   text-left">

                            <h2>Hire By Industry
                            </h2>
                            <p className='p-0 m-0'> Hospitality and tourism</p>
                            <p className='p-0 m-0'> Retail and sales</p>
                            <p className='p-0 m-0'> Education and training</p>
                            <p className='p-0 m-0'> Financial services</p>
                            <p className='p-0 m-0'>  Entertainment and media </p>
                            <p className='p-0 m-0'>  Fintech & NBFC</p>
                            <p className='p-0 m-0'>  Recruitement & HR</p>
                        </div>



                        <div className="col-md-6 col-lg-3 ">

                            <h2>Hire By Profession

                            </h2>
                            <p className='p-0 m-0'>  Education & Institutes</p>
                            <p className='p-0 m-0'>  Events & Planning
                            </p>
                            <p className='p-0 m-0'> Healthcare & Medicals
                            </p>
                            <p className='p-0 m-0'>  Government & Politics
                            </p>
                            <p className='p-0 m-0'>  Digital Promotion
                            </p>
                            <p className='p-0 m-0'>  Manufacturing
                            </p>
                            <p className='p-0 m-0'> Gig services</p>

                        </div>
                        <div className="col-md-6 col-lg-3">

                            <h2>Hire By Location
                            </h2>
                            <p className='p-0 m-0'>  Hire in Mumbai
                            </p>
                            <p className='p-0 m-0'>  Hire in Pune   </p>
                            <p className='p-0 m-0'> Hire in Delhi  </p>
                            <p className='p-0 m-0'> Hire in Bangalore</p>
                            <p className='p-0 m-0'>  Hire in Kolkata</p>
                            <p className='p-0 m-0'>  Hire in Hyderabad</p>
                            <p className='p-0 m-0'>  Hire in Chennai</p>
                        </div>
                        <div className="col-md-6 col-lg-3">

                            <h2>Hire By Demand
                            </h2>
                            <p className='p-0 m-0'>  Entry-Level Company
                            </p>
                            <p className='p-0 m-0'>  Start Up Company
                            </p>
                            <p className='p-0 m-0'> Mid-Scale Company
                            </p>
                            <p className='p-0 m-0'> Large Scale Company
                            </p>
                            <p className='p-0 m-0'>  Multi national Company
                            </p>
                            <p className='p-0 m-0'>  Gig/Service Positions
                            </p>
                            <p className='p-0 m-0'>  Fortune 500
                            </p>
                        </div>
                    </div>
                    <div className="row row-cards mt-3 ps-7 text-left">

                        <div className="col-md-6 col-lg-3">

                            <h2>Hire By Sector
                            </h2>
                            <p className='p-0 m-0'>  IT & Software
                            </p>
                            <p className='p-0 m-0'>  Training Jobs
                            </p>
                            <p className='p-0 m-0'> Sales Jobs
                            </p>
                            <p className='p-0 m-0'> Fresher Jobs
                            </p>
                            <p className='p-0 m-0'>  Medical Jobs
                            </p>
                            <p className='p-0 m-0'>  Bank Jobs
                            </p>
                            <p className='p-0 m-0'>  All Functional Areas
                            </p>
                        </div>
                        <div className="col-md-6 col-lg-3">

                            <h2>Hire By Skills
                            </h2>
                            <p className='p-0 m-0'>  Human Resource Skills
                            </p>
                            <p className='p-0 m-0'>  Trade Execution Skills
                            </p>
                            <p className='p-0 m-0'> Management Skills
                            </p>
                            <p className='p-0 m-0'>  Auditor / ISO Skills
                            </p>
                            <p className='p-0 m-0'>  Authoring Skills
                            </p>
                            <p className='p-0 m-0'>  Automation Skills
                            </p>
                            <p className='p-0 m-0'> Machine Operations Skills
                            </p>

                        </div>
                        <div className="col-md-6 col-lg-3">

                            <h2>Hire By Location
                            </h2>
                            <p className='p-0 m-0'>  Hire in Navi Mumbai
                            </p>
                            <p className='p-0 m-0'>  Hire in Ahmedabad
                            </p>
                            <p className='p-0 m-0'> Hire in Aurangabad
                            </p>
                            <p className='p-0 m-0'> Hire in Mysore
                            </p>
                            <p className='p-0 m-0'>  Hire in Nagpur </p>
                            <p className='p-0 m-0'>  Hire in Nasik
                            </p>
                            <p className='p-0 m-0'>  Hire in Kolhapur </p>
                        </div>
                        <div className="col-md-6 col-lg-3 ">

                            <h2>Hire By Customer Type
                            </h2>
                            <p className='p-0 m-0'> Survey Customers
                            </p>
                            <p className='p-0 m-0'>  Cold Calling Customer
                            </p>
                            <p className='p-0 m-0'> Event or Trade Show
                            </p>
                            <p className='p-0 m-0'> Classified Enquiry

                            </p>
                            <p className='p-0 m-0'> Follow up Enquiry
                            </p>
                            <p className='p-0 m-0'> Cross / Up Selling
                            </p>
                            <p className='p-0 m-0'>  Awareness Calling
                            </p>

                        </div>

                    </div>
                    <hr/> */}
          <div className="row row-cards mt-md-3 ps-md-3 ps-lg-7 ">
            <div className="col-md-12 col-lg-12 text-center">
              <span className="badge badge-outline text-white m-1">
                Admin & Back Office{" "}
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Advertising{" "}
              </span>
              <span className="badge badge-outline text-lime m-1">
                Aviation & Aerospace
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Banking & Insurance{" "}
              </span>
              <span className="badge badge-outline text-green m-1">
                Beauty, Fitness & Personal Care
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Construction & Site Engineering Consulting
              </span>
              <span className="badge badge-outline text-lime m-1">
                Content, Editorial & Journalism
              </span>
              <span className="badge badge-outline text-cyan m-1">
                CSR & Social Service
              </span>
              <span className="badge badge-outline text-green m-1">
                Customer Support
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Computer Operator
              </span>
              <span className="badge badge-outline text-cyan m-1">
                Communication
              </span>
              <span className="badge badge-outline text-teal m-1">
                Data Science & Analytics
              </span>
              <span className="badge badge-outline text-white m-1">
                Delivery & Logistics
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Digital Marketing
              </span>
              <span className="badge badge-outline text-orange m-1">
                Energy & Mining
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Engineering - Hardware & Networks
              </span>
              <span className="badge badge-outline text-lime m-1">
                Environment Health & Safety
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Financial Services
              </span>
              <span className="badge badge-outline text-green m-1">
                Facility Management
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Finance & Accounting
              </span>
              <span className="badge badge-outline text-teal m-1">
                Healthcare & Hospital Staff
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Human Resources
              </span>
              <span className="badge badge-outline text-green m-1">
                IT & Information Security
              </span>
              <span className="badge badge-outline text-lime m-1">
                Legal & Regulatory
              </span>
              <span className="badge badge-outline text-white m-1">
                Maintenance Services
              </span>
              <span className="badge badge-outline text-green m-1">
                Marketing & Branding
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Media Production & Entertainment
              </span>
              <span className="badge badge-outline text-cyan m-1">
                Media Operations
              </span>
              <span className="badge badge-outline text-orange m-1">
                Production & Manufacturing & Engineering
              </span>
              <span className="badge badge-outline text-teal m-1">
                Product Management
              </span>
              <span className="badge badge-outline text-lime m-1">
                Purchase & Supply Chain
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Quality Assurance
              </span>
              <span className="badge badge-outline text-white m-1">
                Research & Development
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Retail & eCommerce
              </span>
              <span className="badge badge-outline text-green m-1">
                Risk Management & Compliance
              </span>
              <span className="badge badge-outline text-orange m-1">
                Sales & Business Development
              </span>
              <span className="badge badge-outline text-yellow m-1">
                Security Services
              </span>
              <span className="badge badge-outline text-cyan m-1">
                Shipping & Maritime
              </span>
              <span className="badge badge-outline text-lime m-1">
                Software Engineering
              </span>
              <span className="badge badge-outline text-white m-1">
                Strategic & Top Management
              </span>
              <span className="badge badge-outline text-teal m-1">
                Tailoring, Apparel & Home Furnishing
              </span>
              <span className="badge badge-outline text-orange m-1">
                Teaching & Training
              </span>
              <span className="badge badge-outline text-yellow m-1">
                UX, Design & Architecture
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterTop;
