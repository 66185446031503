import React from "react";
import CustomSelect from "../../CustomComponent/custom_select";
import CustomInput from "../../CustomComponent/custom_input";
import { useState } from "react";
import { Formik, Form } from "formik";
import performRequest from "../../../APIs/request";
import { companyOnboardingInfo } from "../../../Schema Info/employerOnboardingInfo";
import { companyOnboardingSchema } from "../../../Schemas/Employer Onboarding/employer_schema";
import { Toast } from "../../CustomComponent/toast";
import { FaFloppyDisk } from "react-icons/fa6";
import Footer from "../../../Components/Footer/footer";
import LoadingPage from "../../../Components/layouts/loading";
import { login } from "../../../Redux/User/login_reducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { company } from "../../../Config/config";
const EmployerOnbord = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleCompanyOnboardingSubmit = async (values) => {
    const formData = new FormData();
    formData.append("companyLogo", values.companyLogo);
    formData.append("fullName", values.fullName);
    formData.append("companyName", values.companyName);
    formData.append("designation", values.designation);
    formData.append("companyAddress", values.companyAddress);
    formData.append("teamSize", values.teamSize);
    formData.append("isAgency", values.isAgency);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("contactNumber", values.contactNumber);

    let data = {
      fullName: values.fullName,
      email: values.email,
      contactNumber: values.contactNumber,
      password: values.password,
      userRole: company,
    };
    setLoading(true);
    performRequest("POST", "/auth/register", data)
      .then((response) => {
        if (response.status === 201) {
          performRequest("POST", "/employer/newEmployerOnboarding", formData)
            .then((res) => {
              if (res.status === 201) {
                Toast("success", "Login  Successfully");
                let data = {...response.data, profilePicture:res.data.companyLogo}
                dispatch(login(data));
                Navigate("/");
              } else {
                Toast("error", "Server Error");
              }
            })
            .catch((err) => {
              Toast("error", "Server Error");
              console.error("Error", err);
            })
            .finally(() => setLoading(false));

          Toast("success", "Login Successfully");
        } else if (response.status === 200) {
          Toast("error", "Employer already exist, Please login");
          setLoading(false);
          Navigate("/account-login");
        } else {
          Toast("error", "Server Error");
          setLoading(false);
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="page">
      <div className="page-wrapper">
        <div className="container-xl">
          <div className="page-body">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0">
                Build your rockstar team.
              </h2>
              <p className="section-description mb-4 p-0">
                Create your profile. It will help us to connect you to right
                talent.
              </p>
            </div>

            <div className="row">
              <div className="col-3">
                <div className="card">
                  <div className="card-body">
                    <h3 className="m-0 p-0">
                      Adding more information about your organization will help
                      us connect you to more candidates.
                    </h3>
                  </div>
                </div>
              </div>

              <div className="col-9">
                <div className="card p-2">
                  <h3 className="m-3 p-0">
                    Tell us about you & your organization:
                  </h3>
                  <Formik
                    initialValues={companyOnboardingInfo}
                    validationSchema={companyOnboardingSchema}
                    onSubmit={handleCompanyOnboardingSubmit}
                  >
                    {(props) => (
                      <Form id="pj-main">
                        <fieldset>
                          <div className="row">
                            <div className="col-6">
                              <div className="card m-1 p-3">
                                <div className="my-2">
                                  <label className="form-label required m-0 p-0">
                                    Your full name
                                  </label>
                                  <CustomInput type="text" name="fullName" />
                                  {props.touched.fullName &&
                                    props.errors.fullName && (
                                      <p className="text-danger">
                                        {props.errors.fullName}
                                      </p>
                                    )}
                                </div>
                                <div className="my-2">
                                  <label className="form-label required m-0 p-0">
                                    Enter Mobile
                                  </label>
                                  <CustomInput
                                    type="text"
                                    name="contactNumber"
                                  />
                                  {props.touched.contactNumber &&
                                    props.errors.contactNumber && (
                                      <p className="text-danger">
                                        {props.errors.contactNumber}
                                      </p>
                                    )}
                                </div>
                                <div className="my-2">
                                  <label className="form-label required m-0 p-0">
                                    Enter Password
                                  </label>
                                  <CustomInput type="text" name="password" />
                                  {props.touched.password &&
                                    props.errors.password && (
                                      <p className="text-danger">
                                        {props.errors.password}
                                      </p>
                                    )}
                                </div>
                                <div className="my-2">
                                  <label className="form-label required m-0 p-0">
                                    Your designation
                                  </label>
                                  <CustomSelect type="text" name="designation">
                                    <option value="">Your Designation</option>
                                    <option value="CEO">CEO</option>
                                    <option value="CTO">CTO</option>
                                    <option value="CFO">CFO</option>
                                    <option value="CMO">CMO</option>
                                    <option value="Managing Director">
                                      Managing Director
                                    </option>
                                    <option value="Hiring Director">
                                      Hiring Director
                                    </option>
                                    <option value="Human Resource">
                                      Human Resource
                                    </option>
                                  </CustomSelect>
                                  {props.touched.designation &&
                                    props.errors.designation && (
                                      <p className="text-danger">
                                        {props.errors.designation}
                                      </p>
                                    )}
                                </div>
                                <div className="my-2">
                                  <label className="form-label required m-0 p-0">
                                    Your business email
                                  </label>
                                  <CustomInput type="email" name="email" />
                                  {props.touched.email &&
                                    props.errors.email && (
                                      <p className="text-danger">
                                        {props.errors.email}
                                      </p>
                                    )}
                                </div>

                                <div className="my-2">
                                  <label className="form-check my-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="isAgency"
                                      onChange={(e) => {
                                        if (props.values.isAgency === "false") {
                                          props.setFieldValue(
                                            "isAgency",
                                            "true"
                                          );
                                        } else {
                                          props.setFieldValue(
                                            "isAgency",
                                            "false"
                                          );
                                        }
                                      }}
                                    />
                                    <span className="form-check-label required">
                                      Are you a third party hiring agency or
                                      career consultancy?
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="card m-1 p-3">
                                <div className="my-2">
                                  <label className="form-label required m-0 p-0">
                                    Company Logo
                                  </label>
                                  <CustomInput
                                    type="file"
                                    name="companyLogo"
                                    accept="image/*"
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "companyLogo",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  {props.touched.companyLogo &&
                                    props.errors.companyLogo && (
                                      <p className="text-danger">
                                        {props.errors.companyLogo}
                                      </p>
                                    )}
                                </div>
                                <div className="my-2">
                                  <label className="form-label required p-0 m-0">
                                    Company Name
                                  </label>
                                  <CustomInput type="text" name="companyName" />
                                  {props.touched.companyName &&
                                    props.errors.companyName && (
                                      <p className="text-danger">
                                        {props.errors.companyName}
                                      </p>
                                    )}
                                </div>

                                <div className="my-2">
                                  <label className="form-label required p-0 m-0">
                                    Number of Employees working
                                  </label>
                                  <CustomSelect type="text" name="teamSize">
                                    <option value="">Team Size</option>
                                    <option value="0-50">0-50</option>
                                    <option value="51-100">51-100</option>
                                    <option value="101-150">101-150</option>
                                    <option value="151-250">151-250</option>
                                    <option value="251-500">251-500</option>
                                    <option value="501-1000">501-1000</option>
                                    <option value="1000">Above 1000</option>
                                  </CustomSelect>
                                  {props.touched.teamSize &&
                                    props.errors.teamSize && (
                                      <p className="text-danger">
                                        {props.errors.teamSize}
                                      </p>
                                    )}
                                </div>

                                <div className="my-2">
                                  <label className="form-label required p-0 m-0">
                                    Address
                                  </label>
                                  <CustomInput
                                    type="text"
                                    name="companyAddress"
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "companyAddress",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {props.touched.companyAddress &&
                                    props.errors.companyAddress && (
                                      <p className="text-danger">
                                        {props.errors.companyAddress}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-end">
                            <button
                              className="btn btn-primary m-2"
                              type="submit"
                            >
                              <FaFloppyDisk fontSize={"1em"} className="me-2" />{" "}
                              Submit
                            </button>
                          </div>
                        </fieldset>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmployerOnbord;
