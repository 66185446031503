import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import performRequest from "../../APIs/request";
import { Toast } from "../CustomComponent/toast";
import LoadingPage from "../../Components/layouts/loading";
import { Formik, Form, Field, FieldArray } from "formik";
import { editProfileInfo } from "../../Schema Info/editprofileinfo";
import { editProfileSchema } from "../../Schemas/User/edit_profile_schema";
import CustomInput from "../CustomComponent/custom_input";

import { forgotPasswordSchema } from "../../Schemas/User/forgot_password_schema";
import { editCompanyProfileSchema } from "../../Schemas/User/edit_companyprofile_schema";
import CustomSelect from "../CustomComponent/custom_select";
import { editCompanyProfileInfo } from "../../Schema Info/edit_companyprofileInfo";
import { login } from "../../Redux/User/login_reducer";
import { useDispatch } from "react-redux";
import { company } from "../../Config/config";
import CustomTextArea from "../CustomComponent/custom_textarea";
import { changePasswordSchema } from "../../Schemas/User/change_password_schema";

function Myprofile() {
  const store = useSelector((store) => store?.userLogin);
  const [data, setData] = useState(editProfileInfo);
  const [edata, setEdata] = useState(editCompanyProfileInfo);
  const [inputImage, setInputImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [profilePicture, setProfilePicture] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  useEffect(() => {
    // Dynamic code

    const getMyprofile = () => {
      performRequest("GET", `/user/userbyid/${store?.user.UserId}`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.userRole === company) {
              setEdata(res.data.employeronboardingmodels[0]);
            } else {
              setData(res.data);
            }
          } else {
            Toast("error", "Server Error");
          }
        })
        .catch((err) => {
          console.error("Error", err);
        })
        .finally(() => setLoading(false));
    };

    getMyprofile();
  }, []);
  useEffect(() => {
    performRequest("GET", `/user/userprofilepicturebyid/${store.user?.UserId}`)
      .then((res) => {
        if (res.status === 200) {
          setProfilePicture(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleEditImage = (e) => {
    e.preventDefault();
    imageRef.current.click();
  };

  const handleEditProfile = (values) => {
    const formData = new FormData();
    formData.append("profilePicture", values.profilePicture);
    formData.append("email", values.email);
    formData.append("contactNumber", values.contactNumber);
    formData.append("fullName", values.fullName);

    performRequest(
      "PATCH",
      `/user/updateuserbyuserid/${store?.user?.UserId}`,
      formData,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            login({ ...store.user, profilePicture: res.data.profilePicture })
          );
          Toast("success", "Profile Update Successfully");
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create a preview URL
      setInputImage(imageUrl); // Update the state with the new image
    }
  };

  const handleEditEmployerProfile = (values) => {
    const formData = new FormData();
    for (let key in values) {
      formData.append(key, values[key]);
    }

    performRequest(
      "PATCH",
      `/employer/update-onboarded-employee/${store?.user?.UserId}`,
      formData,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            login({ ...store.user, profilePicture: res.data.companyLogo })
          );
          Toast("success", "Profile Update Successfully");
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        Toast("error", "Server Error");
        console.error("Error", err);
      });
  };

  const handelSubmit = (values) => {
    const data = { password: values.password, oldPassword:values.oldPassword };
    performRequest("PATCH", `/auth/changepassword/${store?.user?.UserId}`, data)
      .then((res) => {
        if (res.status === 200) {
          Toast("success", "Password change successfully");
          handleButtonClose();
        } else {
          Toast("error", "Server Error");
        }
      })
      .catch((err) => {
        if(err.response.status === 401){
          Toast("error", "Wrong Credentials")
        }else{
          Toast("error", "Server Error");
        }
        
        console.error("Error", err);
      });
  };
  const handleButtonClose = () => {
    const button = document.getElementById("btn-close");
    button.click();
  };
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="page-wrapper">
      <div className="page-body">
        <div className="container-xl">
          {store?.user?.Role !== company ? (
            <div className="col-12">
              <Formik
                className="card"
                enableReinitialize
                initialValues={data}
                validationSchema={editProfileSchema}
                onSubmit={handleEditProfile}
              >
                {(props) => (
                  <Form className="card">
                    <React.Fragment>
                      <div className="card-header">
                        <h3 className="card-title">My Profile</h3>
                      </div>
                      <div className="card-body">
                        <div className="mb-3">
                          <div className="row row-cards align-items-center">
                            <div className="col-lg-2 text-center col-md-3">
                              <span className="avatar avatar-2xl">
                                {inputImage ? (
                                  <img src={inputImage} alt="" srcSet="" />
                                ) : (
                                  <img src={profilePicture} alt="" srcSet="" />
                                )}
                              </span>
                              <br />
                              <input
                                type="file"
                                ref={imageRef}
                                style={{ display: "none" }} // Hide the input field
                                onChange={(e) => {
                                  handleImageUpload(e);
                                  props.setFieldValue(
                                    "profilePicture",
                                    e.target.files[0]
                                  );
                                }}
                                accept="image/*" // Limit to image files only
                              />

                              <button
                                onClick={handleEditImage}
                                className="btn btn-secondary mt-1 ms-1"
                              >
                                Edit Profile
                              </button>
                              <button
                                className="btn btn-tertiory mt-2 me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-team"
                                type="button"
                              >
                                Set New Password
                              </button>
                            </div>
                            <div className="col-lg-7 col-md-9 col-sm-12">
                              <div className="mb-3 row">
                                <label className="form-label col-3">
                                  Name :
                                </label>
                                <div className="col">
                                  {" "}
                                  <CustomInput
                                    name="fullName"
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "fullName",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {props.touched.fullName &&
                                    props.errors.fullName && (
                                      <p className="text-danger">
                                        {props.errors.fullName}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="form-label col-3">
                                  Email-Address :
                                </label>
                                <div className="col">
                                  {" "}
                                  <CustomInput
                                    name="email"
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "email",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {props.touched.email &&
                                    props.errors.email && (
                                      <p className="text-danger">
                                        {props.errors.email}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="form-label col-3">
                                  Contact Number
                                </label>
                                <div className="col">
                                  {" "}
                                  <CustomInput
                                    type="text"
                                    name="contactNumber"
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        "contactNumber",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {props.touched.contactNumber &&
                                    props.errors.contactNumber && (
                                      <p className="text-danger">
                                        {props.errors.contactNumber}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-end">
                        <button
                          type="submit"
                          // onClick={() => {
                          //   handleEditProfile(props.values);
                          // }}
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      </div>
                    </React.Fragment>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <div className="col-12">
              <Formik
                className="card"
                enableReinitialize
                initialValues={edata}
                validationSchema={editCompanyProfileSchema}
                onSubmit={handleEditEmployerProfile}
              >
                {(props) => (
                  <Form className="card">
                    <React.Fragment>
                      {console.log(props)}
                      <div className="card-header">
                        <h3 className="card-title">My Profile</h3>
                      </div>
                      <div className="card-body">
                        <div className="mb-3">
                          <h3>Employer Personal Details</h3>

                          <div className="row row-cards">
                            {/* coloumn one */}
                            <div className="col-lg-auto  col-md-3 col-sm-6">
                              <span className="avatar avatar-2xl">
                                {inputImage ? (
                                  <img src={inputImage} alt="" srcSet="" />
                                ) : (
                                  <img src={profilePicture} alt="" srcSet="" />
                                )}
                              </span>
                              <br />
                              <input
                                type="file"
                                ref={imageRef}
                                style={{ display: "none" }} // Hide the input field
                                onChange={(e) => {
                                  handleImageUpload(e);
                                  props.setFieldValue(
                                    "companyLogo",
                                    e.target.files[0]
                                  );
                                }}
                                accept="image/*" // Limit to image files only
                              />

                              <button
                                onClick={handleEditImage}
                                className="btn btn-secondary mt-1 ms-1"
                              >
                                Edit Profile
                              </button>
                            </div>
                            {/* coloumn Two */}
                            <div className="col-lg-3 col-md-3 col-sm-12">
                              <div className="mb-3">
                                <label className="form-label">Name</label>
                                <CustomInput
                                  name="fullName"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "fullName",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.fullName &&
                                  props.errors.fullName && (
                                    <p className="text-danger">
                                      {props.errors.fullName}
                                    </p>
                                  )}
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  Designation
                                </label>
                                <CustomInput
                                  type="text"
                                  name="designation"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "designation",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.designation &&
                                  props.errors.designation && (
                                    <p className="text-danger">
                                      {props.errors.designation}
                                    </p>
                                  )}
                              </div>
                            </div>
                            {/* coloumn three */}
                            <div className="col-lg-3 col-md-3">
                              <div className="mb-3">
                                <label className="form-label">
                                  Email-Address
                                </label>
                                <CustomInput
                                  name="email"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "email",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.email && props.errors.email && (
                                  <p className="text-danger">
                                    {props.errors.email}
                                  </p>
                                )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Change Your Password
                                </label>
                                <button
                                  className="btn btn-tertiory  me-2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modal-team"
                                  type="button"
                                >
                                  Set New Password
                                </button>
                              </div>
                            </div>
                            {/* coloumn four */}
                            <div className="col ">
                              <div className="mb-3">
                                <label className="form-label">
                                  Contact Number
                                </label>
                                <CustomInput
                                  type="text"
                                  name="contactNumber"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "contactNumber",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.contactNumber &&
                                  props.errors.contactNumber && (
                                    <p className="text-danger">
                                      {props.errors.contactNumber}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 mt-2">
                          <h3>Company Details</h3>
                          <hr className="mt-0" />
                          <div className="row row-cards">
                            {/* coloumn two */}
                            <div className="col">
                              <div className="mb-3">
                                <label className="form-label">
                                  Company Name
                                </label>
                                <CustomInput
                                  name="companyName"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "companyName",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.companyName &&
                                  props.errors.companyName && (
                                    <p className="text-danger">
                                      {props.errors.companyName}
                                    </p>
                                  )}
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  Company Type
                                </label>
                                <CustomSelect type="text" name="companyType">
                                  <option value="">Company Type</option>
                                  <option value="Sole Proprietorship">Sole Proprietorship</option>
                                  <option value="Partnership">Partnership</option>
                                  <option value="Limited Liability Company (LLC)">Limited Liability Company (LLC)</option>
                                  <option value="Cooperative">Cooperative</option>
                                  <option value="Public Company">Public Company</option>
                                  <option value="Private Company">Private Company</option>
                                  <option value="Corporation">Corporation</option>
                                </CustomSelect>
                                {props.touched.companyType &&
                                  props.errors.companyType && (
                                    <p className="text-danger">
                                      {props.errors.companyType}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Industry</label>
                                <CustomInput
                                  name="industry"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "industry",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.industry &&
                                  props.errors.industry && (
                                    <p className="text-danger">
                                      {props.errors.industry}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  About Company{" "}
                                </label>
                                <CustomTextArea
                             as="textarea"
                             type="text"
                             style={{
                               
                               maxHeight: "content",
                             }}
                                  name="aboutCompany"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "aboutCompany",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.aboutCompany &&
                                  props.errors.aboutCompany && (
                                    <p className="text-danger">
                                      {props.errors.aboutCompany}
                                    </p>
                                  )}
                              </div>
                        
                            </div>
                            {/* coloumn three */}
                            <div className="col">
                              <div className="mb-3">
                                <label className="form-label">Website</label>
                                <CustomInput
                                  type="text"
                                  name="website"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "website",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.website &&
                                  props.errors.website && (
                                    <p className="text-danger">
                                      {props.errors.website}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Company Headquarter{" "}
                                </label>
                                <CustomInput
                                  name="headquarter"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "headquarter",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.headquarter &&
                                  props.errors.headquarter && (
                                    <p className="text-danger">
                                      {props.errors.headquarter}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Industry Name
                                </label>
                                <CustomInput
                                  name="industryName"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "industryName",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.industryName &&
                                  props.errors.industryName && (
                                    <p className="text-danger">
                                      {props.errors.industryName}
                                    </p>
                                  )}
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  Founded Year
                                </label>
                                <CustomInput
                                  name="foundedYear"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "foundedYear",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.foundedYear &&
                                  props.errors.foundedYear && (
                                    <p className="text-danger">
                                      {props.errors.foundedYear}
                                    </p>
                                  )}
                              </div>

                              
                            </div>
                            {/* coloumn Four */}
                            <div className="col">
                              <div className="mb-3">
                                <label className="form-label">Address</label>
                                <CustomTextArea
                                  type="text"
                                 
                                  name="companyAddress"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "companyAddress",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.companyAddress &&
                                  props.errors.companyAddress && (
                                    <p className="text-danger">
                                      {props.errors.companyAddress}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Team Size</label>
                                <CustomSelect type="text" name="teamSize">
                                  <option value="">Team Size</option>
                                  <option value="0-50">0-50</option>
                                  <option value="51-100">51-100</option>
                                  <option value="101-150">101-150</option>
                                  <option value="151-250">151-250</option>
                                  <option value="251-500">251-500</option>
                                  <option value="501-1000">501-1000</option>
                                  <option value="1000">Above 1000</option>
                                </CustomSelect>
                                {props.touched.teamSize &&
                                  props.errors.teamSize && (
                                    <p className="text-danger">
                                      {props.errors.teamSize}
                                    </p>
                                  )}
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Sector Name
                                </label>
                                <CustomInput
                                  name="sectorName"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "sectorName",
                                      e.target.value
                                    );
                                  }}
                                />
                                {props.touched.sectorName &&
                                  props.errors.sectorName && (
                                    <p className="text-danger">
                                      {props.errors.sectorName}
                                    </p>
                                  )}
                              </div>

                           
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="card-footer text-end">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </React.Fragment>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {/* model */}

          <div
            className="modal modal-blur fade"
            id="modal-team"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Change Your Password</h5>
                  <button
                    type="button"
                    id={"btn-close"}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Formik
                  initialValues={{oldPassword:"", password: "", confirmPassword: "" }}
                  validationSchema={changePasswordSchema}
                  onSubmit={handelSubmit}
                >
                  {(props) => (
                    <Form>
                      {console.log(props)}
                      <div className="card-body p-4">
                        <p className="text-muted m-1">
                          Please enter and confirm your new password.
                        </p>
                        <div className="mb-3 m-1">
                          <label className="form-label">Old Password</label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="oldPassword"
                              autoComplete="off"
                              placeholder="Enter old password"
                              type={showOldPassword ? "text" : "password"}
                            />

                            <span className="input-group-text">
                              <span
                                onClick={() => setShowOldPassword(!showOldPassword)}
                                className="link-secondary"
                                title="Show password"
                                role="button"
                                data-bs-toggle="tooltip"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                </svg>
                              </span>
                            </span>
                          </div>
                          {props.touched.oldPassword && props.errors.oldPassword && (
                            <p className="text-danger">
                              {props.errors.oldPassword}
                            </p>
                          )}
                        </div>
                        <div className="mb-3 m-1">
                          <label className="form-label">New Password</label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="password"
                              autoComplete="off"
                              placeholder="Enter password"
                              type={showPass ? "text" : "password"}
                            />

                            <span className="input-group-text">
                              <span
                                onClick={() => setShowPass(!showPass)}
                                className="link-secondary"
                                title="Show password"
                                role="button"
                                data-bs-toggle="tooltip"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                </svg>
                              </span>
                            </span>
                          </div>
                          {props.touched.password && props.errors.password && (
                            <p className="text-danger">
                              {props.errors.password}
                            </p>
                          )}
                        </div>
                        <div className=" m-1">
                          <label className="form-label">
                            Confirm New Password
                          </label>
                          <div className="input-group input-group-flat">
                            <CustomInput
                              name="confirmPassword"
                              placeholder="Confirm password"
                              autoComplete="off"
                              type={showConfirmPass ? "text" : "password"}
                            />

                            <span className="input-group-text">
                              <span
                                onClick={() =>
                                  setShowConfirmPass(!showConfirmPass)
                                }
                                className="link-secondary"
                                title="Show password"
                                role="button"
                                data-bs-toggle="tooltip"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                                </svg>
                              </span>
                            </span>
                          </div>
                          {props.touched.confirmPassword &&
                            props.errors.confirmPassword && (
                              <p className="text-danger">
                                {props.errors.confirmPassword}
                              </p>
                            )}
                        </div>

                        <div className="form-footer text-end">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Myprofile;
