import React, { useEffect, useState } from "react";
import {
  FaBolt,
  FaMapMarker,
  FaMoneyBill,
  FaArrowAltCircleRight,
} from "react-icons/fa";
import { recruiter } from "../../../Config/config";
import { useSelector } from "react-redux";
import { store } from "../../../Redux/store";
const JobsList = ({ jobData = [] }) => {
  const store = useSelector((store) => store?.userLogin);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const [count, setCount] = useState(15);
  return (
    <>
      {jobData?.map((comp, i) => {
        if (i < count) {
          return (
            <div key={i} className="card m-2 p-2">
              {comp?.usermodel?.userRole === recruiter && (
                <div className="ribbon bg-red">
                  <FaBolt fontSize={"1em"} className="me-2" />
                  URGENT HIRING
                </div>
              )}

              <div className="row">
                <div className="col-lg-2 col-sm-12 col-md-2 text-center">
                  <div className="card">
                    <img src={comp.employeronboardingmodel.companyLogo} />
                  </div>
                  <div className="card bg-primary-lt mt-1">
                    <p className="m-0 p-0">SKILL SCORE</p>
                    <h3 className="m-0 p-0">90%</h3>
                  </div>
                </div>
                <div className="col-10">
                  <h3 className="m-0 p-0">{comp?.jobRole}</h3>
                  <p className="m-0 p-0">
                    at <strong>{comp?.companyName}</strong> in
                    <strong> {comp?.jobDepartment}</strong> department.
                  </p>
                  <span className="badge bg-cyan-lt me-1">POSITIONS: 1</span>

                  {comp?.additionalPerks?.map((data, i) => {
                    return (
                      <span
                        key={i}
                        className="badge badge-outline text-teal me-1"
                      >
                        {data}
                      </span>
                    );
                  })}

                  <div className="row my-2">
                    <div className="col-12">
                      <p className="m-0 p-0">Required Skills:</p>
                      {comp?.skillsDetails?.map((data, i) => {
                        return (
                          <div
                            key={i}
                            className="avatar avatar-md me-1"
                            style={{ backgroundImage: `url(${data?.icon})` }}
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                  <hr className="m-0 p-0" />
                  <div className="row mt-2">
                    <div className="col-9">
                      <p className="text-secondary m-0 p-0">
                        <FaMapMarker fontSize={"1em"} className="me-2" />
                        {comp?.jobLocation}, {comp?.jobCity}, {comp?.jobState}
                      </p>
                      <p className="text-secondary m-0 p-0">
                        <FaMoneyBill fontSize={"1em"} className="me-2" />₹
                        {comp?.salaryPerMonthMin} - ₹{comp?.salaryPerMonthMax}
                      </p>
                    </div>
                    <div className="col-3 text-end">
                      {
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            if (store?.isLoggedIn) {
                              openInNewTab(`/view-jobs/${comp.id}`);
                            } else {
                              alert("Please login to apply jobs");
                            }
                          }}
                        >
                          <FaArrowAltCircleRight
                            fontSize={"1em"}
                            className="me-2"
                          />{" "}
                          Apply Now
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
      {jobData.length > count && (
        <div className="text-center">
          <button
            onClick={() => setCount(count + 15)}
            className="btn btn-primary w-33"
          >
            Load More
          </button>
        </div>
      )}
    </>
  );
};

export default JobsList;
