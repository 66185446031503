import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Footer/footer";
import { useNavigate } from "react-router";
import { FaArrowCircleRight } from "react-icons/fa";
import performRequest from "../../../APIs/request";
import { departmentsInfo } from "../../../Schema Info/departmentsInfo";
import Pagination from "../../../Components/layouts/pagination";
import GetRandomUserImage from "./get_random_user_image";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../../Redux/Pagination & Filters/filtersReducer";

const GetTestDetails = () => {
  const store = useSelector((store) => store.filters);
  const [department, setDepartments] = useState(departmentsInfo);
  const [data, setData] = new useState([]);
  const [page, setCurrPage] = useState(store.currentPage);
  const [showPage, setShowPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);
  const [userData, setuserData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const getResumeBasedOnSkills = () => {
      performRequest("GET", "/demouser/alldemouser")
        .then((res) => {
          setuserData(res.data);
        })
        .catch((err) => {
          console.error("Error", err);
        });
    };
    getResumeBasedOnSkills();
  }, []);

  useEffect(() => {
    let ft = filters.map((f) => f.value);
    performRequest(
      "POST",
      `/test/alltestsbydepartment?page=${store.currentPage}`,
      ft
    )
      .then((res) => {
        setData(res.data.data);
        setTotalData(res.data.totalData);
        setTotalPages(Math.ceil(res.data.totalData / 20));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [refresh, store.currentPage, filters]);
  const takeTest = (id) => {
    navigate(`/get-skill-badges/${id}`);
  };

  useEffect(() => {
    performRequest("GET", "/test/getdepartmentcount")
      .then((res) => setDepartments(res.data))
      .catch((err) => console.error(err));
  }, []);
  const removeFilter = (ele) => {
    ele.ele.checked = false;
    let arr = filters.filter((f) => f.value !== ele.value);
    setFilters(arr);
    setShowPage(1);
    setPage(1);
  };

  const handelCheckboxChange = (e) => {
    if (e.target.checked) {
      let arr = filters?.filter((ele) => ele.value !== e.target.value);
      let obj = {
        key: e.target.name,
        value: e.target.value,
        ele: e.target,
      };
      arr.unshift(obj);
      setFilters(arr);
      setShowPage(1);
      setPage(1);
    } else {
      let arr = filters?.filter((ele) => ele.value !== e.target.value);
      setFilters(arr);
      setShowPage(1);
      setPage(1);
    }
  };
  useEffect(() => {
    setCurrPage(store.currentPage);
    setShowPage(Math.floor((store.currentPage - 1) / 5) * 5 + 1);
  }, [store.currentPage]);
  const setPage = (p) => {
    dispatch(setCurrentPage(p));
  };
  return (
    <div className="Page">
      <div className="page-wrapper">
        <div className="page-body">
          <div className="container-xxl">
            <div className="row row-cards">
              <div className="col-12">
                <div className="section-header">
                  <h2 className="section-title section-title-lg m-0 p-0">
                    GET YOURSELF BADGED.
                  </h2>
                  <p className="section-description mb-4 p-0">
                    On finishing this test you will be rewarded with badge and
                    certificate for having skills in.
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-4">
                    <div className="card">
                      <h3 className="m-2 p-2">Select Departments</h3>
                      <div>
                        {filters?.map((ele, index) => {
                          return (
                            <span
                              className="m-1 tag"
                              key={index}
                              onClick={() => removeFilter(ele)}
                            >
                              {ele.value}
                              <a href="#" className="btn-close"></a>
                            </span>
                          );
                        })}
                      </div>
                      <div className="list-group list-group-flush">
                        {department?.map((department, i) => {
                          return (
                            <span
                              key={i}
                              className="list-group-item list-group-item-action py-2 px-2"
                            >
                              <input
                                className="form-check-input me-2 cursor-pointer"
                                type="checkbox"
                                onChange={(e) => handelCheckboxChange(e)}
                                name="Department"
                                value={department?.dep}
                              />
                              <span className="text-start fs-5">
                                {department.dep.toUpperCase()}
                              </span>
                              <span className="badge bg-blue-lt ms-2">
                                {department.count || 0}
                              </span>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <div className="card pb-2">
                      <div className="m-3 p-0">
                        <h3 className="m-0 p-0">Most Enrolled Tests</h3>
                        <p className="m-0 p-0">
                          Total tests found: {totalData}
                        </p>
                      </div>
                      <hr className="m-0 p-0" />
                      <div className="row row-deck row-cards m-1">
                        {data.map((test, i) => {
                          return (
                            <div
                              className="col-lg-3 col-md-6 col-sm-12"
                              key={i}
                            >
                              <div className="card">
                                <div className="text-center m-2">
                                  <img width={"50%"} src={test?.icon} />
                                </div>
                                <div className="card-body m-0 p-2">
                                  <h3
                                    style={{
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 1,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    className="card-title m-0 p-0 fs-3"
                                  >
                                    {test.name}
                                  </h3>

                                  <p>
                                    <span className="badge badge-md bg-lime-lt m-0 p-0">
                                      {(test?.level || "N/A").toUpperCase()}{" "}
                                      LEVEL
                                    </span>
                                  </p>
                                  <hr className="my-2 p-0" />

                                  <div className="col-auto">
                                    <div className="avatar-list avatar-list-stacked ">
                                      {userData && (
                                        <GetRandomUserImage
                                          max={Math.floor(
                                            11 * Math.random() + 10
                                          )}
                                          userData={userData}
                                          classes={"avatar avatar-xs rounded"}
                                        />
                                      )}
                                    </div>
                                    <span>
                                      +{Math.floor(Math.random() * 900)}{" "}
                                      Enrolled
                                    </span>
                                  </div>
                                  <div className="col-auto ms-auto">
                                    <button
                                      onClick={() => takeTest(test?.id)}
                                      className="btn btn-primary w-100"
                                    >
                                      <FaArrowCircleRight
                                        fontSize={"1em"}
                                        className="me-2"
                                      />{" "}
                                      Take Test
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div>
                        <Pagination
                          page={page}
                          setPage={setPage}
                          totalPages={totalPages}
                          pageDatalength={data?.length}
                          showPage={showPage}
                          setShowPage={setShowPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default GetTestDetails;
