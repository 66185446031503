export const departmentsInfo = [
  {
    dep: "Admin & Back Office",
    roles: [
      "Administrative Assistant",
      "Office Manager",
      "Executive Assistant",
      "Receptionist",
      "Data Entry Clerk",
      "Office Clerk",
      "Administrative Coordinator",
      "Office Administrator",
      "Administrative Supervisor",
      "Secretary",
      "File Clerk",
      "Office Support Specialist",
      "Operations Assistant",
      "Mail Clerk",
      "Administrative Officer",
    ],
  },
  {
    dep: "Advertising",
    roles: [
      "Advertising Manager",
      "Copywriter",
      "Media Planner",
      "Creative Director",
      "Account Executive",
      "Art Director",
      "Media Buyer",
      "Market Research Analyst",
      "Advertising Sales Agent",
      "Digital Advertising Specialist",
      "Brand Strategist",
      "Media Director",
      "Campaign Manager",
      "Graphic Designer",
      "Production Manager",
    ],
  },
  {
    dep: "Aviation & Aerospace",
    roles: [
      "Aerospace Engineer",
      "Pilot",
      "Air Traffic Controller",
      "Aircraft Mechanic",
      "Flight Attendant",
      "Avionics Technician",
      "Flight Engineer",
      "Aeronautical Engineer",
      "Aviation Safety Inspector",
      "Airline Operations Manager",
      "Cabin Crew",
      "Flight Dispatcher",
      "Ground Crew",
      "Airline Pilot",
      "Aviation Maintenance Technician",
    ],
  },
  {
    dep: "Banking & Insurance",
    roles: [
      "Bank Teller",
      "Loan Officer",
      "Insurance Underwriter",
      "Financial Analyst",
      "Branch Manager",
      "Credit Analyst",
      "Investment Banker",
      "Risk Manager",
      "Claims Adjuster",
      "Insurance Agent",
      "Financial Planner",
      "Treasury Analyst",
      "Mortgage Advisor",
      "Actuary",
      "Compliance Officer",
    ],
  },
  {
    dep: "Beauty, Fitness & Personal Care",
    roles: [
      "Beautician",
      "Fitness Trainer",
      "Massage Therapist",
      "Hair Stylist",
      "Nail Technician",
      "Personal Trainer",
      "Makeup Artist",
      "Spa Manager",
      "Esthetician",
      "Yoga Instructor",
      "Barber",
      "Cosmetologist",
      "Wellness Coach",
      "Pilates Instructor",
      "Salon Manager",
    ],
  },
  {
    dep: "Communication",
    roles: [
      "Communications Specialist",
      "Public Relations Manager",
      "Media Relations Coordinator",
      "Corporate Communications Manager",
      "Internal Communications Specialist",
      "Crisis Communications Manager",
      "Social Media Manager",
      "Content Strategist",
      "Speechwriter",
      "Community Relations Manager",
      "Public Affairs Specialist",
      "Media Strategist",
      "Press Secretary",
      "Brand Ambassador",
      "Event Coordinator",
    ],
  },
  {
    dep: "Computer Operator",
    roles: [
      "Computer Operator",
      "Data Entry Operator",
      "Systems Operator",
      "IT Support Specialist",
      "Network Administrator",
      "Help Desk Technician",
      "Database Administrator",
      "Systems Analyst",
      "Technical Support Engineer",
      "IT Technician",
      "Computer Technician",
      "IT Systems Administrator",
      "Mainframe Operator",
      "Backup Operator",
      "Print Operator",
    ],
  },
  {
    dep: "Construction Consulting",
    roles: [
      "Construction Consultant",
      "Project Manager",
      "Site Engineer",
      "Construction Manager",
      "Civil Engineer",
      "Quantity Surveyor",
      "Safety Manager",
      "Structural Engineer",
      "Construction Estimator",
      "Building Inspector",
      "Project Coordinator",
      "Cost Engineer",
      "Contracts Manager",
      "Site Supervisor",
      "Planning Engineer",
    ],
  },
  {
    dep: "Site Engineering Consulting",
    roles: [
      "Site Engineer",
      "Civil Engineer",
      "Structural Engineer",
      "Project Engineer",
      "Geotechnical Engineer",
      "Environmental Engineer",
      "Construction Engineer",
      "Field Engineer",
      "Planning Engineer",
      "Surveyor",
      "Site Supervisor",
      "Construction Manager",
      "Project Manager",
      "Quantity Surveyor",
      "Urban Planner",
    ],
  },
  {
    dep: "Content, Editorial & Journalism",
    roles: [
      "Content Writer",
      "Editor",
      "Journalist",
      "Proofreader",
      "Copy Editor",
      "Content Strategist",
      "Freelance Writer",
      "News Reporter",
      "Feature Writer",
      "Managing Editor",
      "Editorial Assistant",
      "Columnist",
      "Investigative Reporter",
      "Photojournalist",
      "Web Content Manager",
    ],
  },
  {
    dep: "CSR & Social Service",
    roles: [
      "Social Worker",
      "CSR Coordinator",
      "Program Manager",
      "Community Outreach Coordinator",
      "Nonprofit Manager",
      "Fundraising Coordinator",
      "Volunteer Coordinator",
      "Grant Writer",
      "Community Development Manager",
      "Case Manager",
      "Advocacy Manager",
      "Humanitarian Aid Worker",
      "Community Service Manager",
      "Public Health Educator",
      "Youth Worker",
    ],
  },
  {
    dep: "Customer Support",
    roles: [
      "Customer Service Representative",
      "Support Specialist",
      "Customer Success Manager",
      "Technical Support Specialist",
      "Help Desk Technician",
      "Customer Support Manager",
      "Call Center Representative",
      "Customer Care Specialist",
      "Client Services Manager",
      "Customer Relations Specialist",
      "Customer Service Manager",
      "Service Desk Analyst",
      "Customer Experience Specialist",
      "Complaint Resolution Specialist",
      "Customer Operations Specialist",
    ],
  },
  {
    dep: "Data Science & Analytics",
    roles: [
      "Data Scientist",
      "Data Analyst",
      "Machine Learning Engineer",
      "Data Engineer",
      "Business Intelligence Analyst",
      "Statistician",
      "Data Architect",
      "Big Data Analyst",
      "Quantitative Analyst",
      "Data Visualization Specialist",
      "Operations Research Analyst",
      "Data Mining Specialist",
      "Predictive Modeler",
      "Decision Scientist",
      "Research Analyst",
    ],
  },
  {
    dep: "Delivery & Logistics",
    roles: [
      "Delivery Driver",
      "Logistics Coordinator",
      "Warehouse Manager",
      "Supply Chain Manager",
      "Inventory Specialist",
      "Shipping Coordinator",
      "Fleet Manager",
      "Distribution Manager",
      "Freight Forwarder",
      "Transport Manager",
      "Logistics Analyst",
      "Logistics Manager",
      "Procurement Specialist",
      "Supply Chain Analyst",
      "Operations Manager",
    ],
  },
  {
    dep: "Digital Marketing",
    roles: [
      "Digital Marketing Specialist",
      "SEO Analyst",
      "Content Marketer",
      "Social Media Manager",
      "Email Marketing Specialist",
      "PPC Specialist",
      "Digital Marketing Manager",
      "Conversion Rate Optimization Specialist",
      "Digital Strategist",
      "Affiliate Marketing Manager",
      "Content Strategist",
      "Web Analytics Specialist",
      "E-commerce Specialist",
      "Marketing Automation Specialist",
      "Online Advertising Manager",
    ],
  },
  {
    dep: "Energy & Mining",
    roles: [
      "Mining Engineer",
      "Energy Analyst",
      "Geologist",
      "Petroleum Engineer",
      "Renewable Energy Specialist",
      "Drilling Engineer",
      "Environmental Consultant",
      "Hydrologist",
      "Seismologist",
      "Mining Safety Engineer",
      "Energy Manager",
      "Energy Consultant",
      "Resource Geologist",
      "Metallurgist",
      "Mine Surveyor",
    ],
  },
  {
    dep: "Hardware & Networks",
    roles: [
      "Network Engineer",
      "System Administrator",
      "IT Support Specialist",
      "Network Administrator",
      "Hardware Technician",
      "Network Security Specialist",
      "Network Architect",
      "Field Service Technician",
      "Infrastructure Engineer",
      "Data Center Technician",
      "Network Analyst",
      "Network Manager",
      "Telecommunications Specialist",
      "IT Infrastructure Manager",
      "Systems Integration Specialist",
    ],
  },
  {
    dep: "Environment Health & Safety",
    roles: [
      "EHS Manager",
      "Safety Officer",
      "Environmental Scientist",
      "Industrial Hygienist",
      "Safety Specialist",
      "Environmental Health Technician",
      "Safety Engineer",
      "Compliance Specialist",
      "Ergonomist",
      "EHS Coordinator",
      "Environmental Consultant",
      "Occupational Health Specialist",
      "Hazardous Materials Specialist",
      "Safety Trainer",
      "Risk Assessment Specialist",
    ],
  },
  {
    dep: "Facility Management",
    roles: [
      "Facility Manager",
      "Maintenance Supervisor",
      "Building Manager",
      "Janitorial Supervisor",
      "Maintenance Technician",
      "Property Manager",
      "Facility Coordinator",
      "Groundskeeper",
      "Custodial Supervisor",
      "Operations Manager",
      "Facilities Coordinator",
      "Building Engineer",
      "Facilities Planner",
      "Security Supervisor",
      "HVAC Technician",
    ],
  },
  {
    dep: "Finance & Accounting",
    roles: [
      "Accountant",
      "Financial Analyst",
      "Auditor",
      "Controller",
      "Tax Specialist",
      "Accounts Payable Clerk",
      "Accounts Receivable Clerk",
      "Financial Manager",
      "Bookkeeper",
      "Budget Analyst",
      "Treasury Analyst",
      "Cost Accountant",
      "Internal Auditor",
      "Financial Planner",
      "Revenue Manager",
    ],
  },
  {
    dep: "Financial Services",
    roles: [
      "Financial Advisor",
      "Investment Analyst",
      "Portfolio Manager",
      "Wealth Manager",
      "Financial Planner",
      "Credit Analyst",
      "Risk Analyst",
      "Trader",
      "Private Banker",
      "Financial Consultant",
      "Investment Consultant",
      "Loan Processor",
      "Asset Manager",
      "Compliance Officer",
      "Bank Manager",
    ],
  },
  {
    dep: "Healthcare & Hospital Staff",
    roles: [
      "Registered Nurse",
      "Medical Assistant",
      "Physician",
      "Pharmacist",
      "Lab Technician",
      "Radiologic Technologist",
      "Physical Therapist",
      "Occupational Therapist",
      "Medical Biller",
      "Medical Coder",
      "Nurse Practitioner",
      "Patient Care Technician",
      "Medical Receptionist",
      "Surgeon",
      "Healthcare Administrator",
    ],
  },
  {
    dep: "Human Resources",
    roles: [
      "HR Manager",
      "Recruiter",
      "HR Generalist",
      "HR Coordinator",
      "Payroll Specialist",
      "Training and Development Manager",
      "Benefits Specialist",
      "HR Assistant",
      "HR Business Partner",
      "Employee Relations Specialist",
      "Compensation Analyst",
      "Talent Acquisition Specialist",
      "HR Consultant",
      "HRIS Analyst",
      "Labor Relations Specialist",
    ],
  },
  {
    dep: "IT & Information Security",
    roles: [
      "IT Manager",
      "Cybersecurity Analyst",
      "Network Security Engineer",
      "IT Security Specialist",
      "Information Security Manager",
      "Penetration Tester",
      "Security Architect",
      "Security Consultant",
      "IT Auditor",
      "Risk Analyst",
      "Security Operations Center (SOC) Analyst",
      "Identity and Access Management (IAM) Specialist",
      "Data Privacy Officer",
      "Security Software Developer",
      "Incident Responder",
    ],
  },
  {
    dep: "Legal & Regulatory",
    roles: [
      "Lawyer",
      "Paralegal",
      "Legal Assistant",
      "Compliance Officer",
      "Regulatory Affairs Specialist",
      "Corporate Counsel",
      "Legal Analyst",
      "Contract Manager",
      "Litigation Support Specialist",
      "Legal Secretary",
      "Legal Researcher",
      "Trademark Specialist",
      "Patent Agent",
      "Legal Operations Manager",
      "Court Clerk",
    ],
  },
  {
    dep: "Maintenance Services",
    roles: [
      "Maintenance Technician",
      "Facility Maintenance Manager",
      "Equipment Maintenance Technician",
      "Maintenance Engineer",
      "HVAC Technician",
      "Electrical Maintenance Technician",
      "Plumber",
      "Carpenter",
      "Grounds Maintenance Worker",
      "Maintenance Planner",
      "Industrial Maintenance Mechanic",
      "Building Maintenance Worker",
      "Janitor",
      "Maintenance Supervisor",
      "Maintenance Scheduler",
    ],
  },
  {
    dep: "Marketing & Branding",
    roles: [
      "Marketing Manager",
      "Brand Manager",
      "Market Research Analyst",
      "Product Manager",
      "Marketing Coordinator",
      "Content Marketing Manager",
      "Social Media Strategist",
      "Marketing Director",
      "Advertising Coordinator",
      "Promotions Manager",
      "Event Marketing Specialist",
      "Brand Strategist",
      "Marketing Analyst",
      "Communications Manager",
      "Public Relations Specialist",
    ],
  },
  {
    dep: "Media Operations",
    roles: [
      "Media Planner",
      "Media Buyer",
      "Broadcast Technician",
      "Media Sales Executive",
      "Program Director",
      "Traffic Coordinator",
      "Media Research Analyst",
      "Media Production Assistant",
      "Media Account Manager",
      "Media Operations Manager",
      "Media Scheduler",
      "Broadcast Engineer",
      "Media Director",
      "News Producer",
      "Studio Technician",
    ],
  },
  {
    dep: "Media Production & Entertainment",
    roles: [
      "Producer",
      "Director",
      "Scriptwriter",
      "Editor",
      "Camera Operator",
      "Sound Engineer",
      "Lighting Technician",
      "Production Assistant",
      "Set Designer",
      "Makeup Artist",
      "Animator",
      "Visual Effects Artist",
      "Actor",
      "Casting Director",
      "Production Manager",
    ],
  },
  {
    dep: "Product Management",
    roles: [
      "Product Manager",
      "Product Owner",
      "Product Development Manager",
      "Product Marketing Manager",
      "Technical Product Manager",
      "Associate Product Manager",
      "Product Analyst",
      "Product Designer",
      "Scrum Master",
      "Product Operations Manager",
      "Product Strategy Manager",
      "Product Coordinator",
      "Product Lifecycle Manager",
      "Product Data Analyst",
      "Product Innovation Manager",
    ],
  },
  {
    dep: "Production & Manufacturing",
    roles: [
      "Production Manager",
      "Manufacturing Engineer",
      "Quality Control Inspector",
      "Assembly Line Worker",
      "Production Supervisor",
      "Machine Operator",
      "Maintenance Technician",
      "CNC Machinist",
      "Industrial Engineer",
      "Production Planner",
      "Production Coordinator",
      "Plant Manager",
      "Warehouse Manager",
      "Packaging Operator",
      "Forklift Operator",
    ],
  },
  {
    dep: "Purchase & Supply Chain",
    roles: [
      "Supply Chain Manager",
      "Purchasing Manager",
      "Procurement Specialist",
      "Inventory Manager",
      "Logistics Manager",
      "Buyer",
      "Supply Chain Analyst",
      "Supplier Quality Engineer",
      "Sourcing Specialist",
      "Warehouse Manager",
      "Demand Planner",
      "Materials Manager",
      "Category Manager",
      "Purchasing Coordinator",
      "Supply Chain Planner",
    ],
  },
  {
    dep: "Quality Assurance",
    roles: [
      "Quality Assurance Manager",
      "Quality Control Inspector",
      "Quality Assurance Analyst",
      "Quality Engineer",
      "QA Tester",
      "Quality Control Manager",
      "QA Specialist",
      "Quality Technician",
      "QA Coordinator",
      "Compliance Specialist",
      "Quality Auditor",
      "Supplier Quality Engineer",
      "Validation Engineer",
      "QA Automation Engineer",
      "Quality Control Supervisor",
    ],
  },
  {
    dep: "Research & Development",
    roles: [
      "R&D Manager",
      "Research Scientist",
      "Product Development Scientist",
      "Clinical Research Coordinator",
      "R&D Engineer",
      "Research Analyst",
      "Innovation Manager",
      "R&D Technician",
      "Lab Manager",
      "Research Associate",
      "Clinical Research Associate",
      "Biostatistician",
      "Research Fellow",
      "R&D Project Manager",
      "Laboratory Technician",
    ],
  },
  {
    dep: "Retail & eCommerce",
    roles: [
      "Retail Manager",
      "Store Manager",
      "Sales Associate",
      "Merchandiser",
      "Customer Service Representative",
      "eCommerce Manager",
      "Retail Buyer",
      "Inventory Manager",
      "Cashier",
      "Visual Merchandiser",
      "Retail Operations Manager",
      "Online Store Manager",
      "Product Manager",
      "Category Manager",
      "Logistics Coordinator",
    ],
  },
  {
    dep: "Risk Management & Compliance",
    roles: [
      "Risk Manager",
      "Compliance Officer",
      "Internal Auditor",
      "Fraud Analyst",
      "Risk Analyst",
      "Regulatory Compliance Specialist",
      "Compliance Manager",
      "Loss Prevention Manager",
      "Security Risk Analyst",
      "Operational Risk Manager",
      "Compliance Analyst",
      "Corporate Risk Manager",
      "Ethics Officer",
      "Anti-Money Laundering Specialist",
      "Governance Risk and Compliance (GRC) Analyst",
    ],
  },
  {
    dep: "Sales & Business Development",
    roles: [
      "Sales Manager",
      "Business Development Manager",
      "Sales Representative",
      "Account Manager",
      "Sales Executive",
      "Sales Consultant",
      "Inside Sales Representative",
      "Territory Sales Manager",
      "Sales Coordinator",
      "Sales Operations Manager",
      "Business Development Executive",
      "Client Relationship Manager",
      "Key Account Manager",
      "Sales Analyst",
      "Regional Sales Manager",
    ],
  },
  {
    dep: "Security Services",
    roles: [
      "Security Guard",
      "Security Manager",
      "Loss Prevention Officer",
      "Security Analyst",
      "Security Consultant",
      "Surveillance Operator",
      "Security Systems Technician",
      "Security Supervisor",
      "Executive Protection Agent",
      "Private Investigator",
      "Alarm Technician",
      "Cybersecurity Specialist",
      "Security Patrol Officer",
      "Event Security Officer",
      "Security Operations Manager",
    ],
  },
  {
    dep: "Shipping & Maritime",
    roles: [
      "Maritime Engineer",
      "Ship Captain",
      "Marine Biologist",
      "Dock Worker",
      "Marine Surveyor",
      "Naval Architect",
      "Marine Engineer",
      "Harbor Master",
      "Shipping Coordinator",
      "Port Operations Manager",
      "Logistics Coordinator",
      "Cargo Inspector",
      "Freight Broker",
      "Marine Technician",
      "Deck Officer",
    ],
  },
  {
    dep: "Software Engineering",
    roles: [
      "Software Engineer",
      "Full Stack Developer",
      "Frontend Developer",
      "Backend Developer",
      "DevOps Engineer",
      "Software Architect",
      "QA Engineer",
      "Mobile App Developer",
      "Game Developer",
      "Embedded Software Engineer",
      "Data Engineer",
      "Cloud Engineer",
      "Software Development Manager",
      "Technical Lead",
      "Systems Developer",
    ],
  },
  {
    dep: "Strategic & Top Management",
    roles: [
      "CEO",
      "COO",
      "CFO",
      "CTO",
      "Chief Marketing Officer",
      "Chief HR Officer",
      "Chief Compliance Officer",
      "Chief Risk Officer",
      "Chief Information Officer",
      "Chief Product Officer",
      "Chief Strategy Officer",
      "President",
      "Vice President",
      "Director of Operations",
      "General Manager",
    ],
  },
  {
    dep: "Tailoring, Apparel",
    roles: [
      "Tailor",
      "Seamstress",
      "Pattern Maker",
      "Apparel Designer",
      "Fashion Designer",
      "Textile Designer",
      "Garment Technician",
      "Alterations Specialist",
      "Dressmaker",
      "Costume Designer",
      "Clothing Manufacturer",
      "Quality Control Inspector",
      "Production Manager",
      "Sample Maker",
      "Pattern Grader",
    ],
  },
  {
    dep: "Teaching & Training",
    roles: [
      "Teacher",
      "Trainer",
      "Instructional Designer",
      "Curriculum Developer",
      "Educational Consultant",
      "Training Manager",
      "Corporate Trainer",
      "Academic Advisor",
      "Instructional Coordinator",
      "E-learning Specialist",
      "School Principal",
      "Education Administrator",
      "Special Education Teacher",
      "Teaching Assistant",
      "Professor",
    ],
  },
  {
    dep: "UX, Design & Architecture",
    roles: [
      "UX Designer",
      "UI Designer",
      "Graphic Designer",
      "Architect",
      "Interior Designer",
      "Product Designer",
      "Industrial Designer",
      "Interaction Designer",
      "Web Designer",
      "Visual Designer",
      "Design Researcher",
      "User Researcher",
      "Service Designer",
      "Information Architect",
      "Landscape Architect",
    ],
  },
];
