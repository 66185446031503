import React from "react";

const GetRandomUserImage = ({
  max = 20,
  userData = [],
  classes = "avatar",
}) => {
  const getRandomImage = () => {
    let arr = [];
    for (let i = 0; i < max; i++) {
      let x = Math.floor(Math.random() * 49);
      if (userData && userData[x]) {
        arr.push(userData[x]);
      }
    }
    return arr;
  };
  return (
    <div>
      {" "}
      {getRandomImage()?.map((item, index) => {
        return (
          <span
            key={index}
            className={classes}
            style={{
              backgroundImage: `url(${item?.image})`,
            }}
          ></span>
        );
      })}
    </div>
  );
};

export default GetRandomUserImage;
