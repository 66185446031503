import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/footer";
import {
  FaAngleDoubleRight,
  FaArrowAltCircleRight,
  FaArrowCircleRight,
  FaLaptop,
} from "react-icons/fa";
import { FaBuildingUser } from "react-icons/fa6";
import { useNavigate } from "react-router";
import performRequest from "../../APIs/request";
import FormSubmitButton from "../../Components/layouts/formSubmitButton";
import { Formik, Form } from "formik";
import { BookDemoInfo } from "../../Schema Info/info";
import { BookDemoSchema } from "../../Schemas/Demo/bookDemo_schema";
import CustomInput from "../CustomComponent/custom_input";
import { useSelector } from "react-redux";
const testimonials = [
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/a/a6/Logo_TCS.svg",
    writtenBy: "Amit Patel, CEO",
    companyName: "TCS",
    description:
      "Your team has consistently delivered top-notch engineers. Their expertise and commitment have helped us streamline our development processes and achieve faster time-to-market.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/f/f7/Infosys_logo.svg",
    writtenBy: "Neha Gupta, CTO",
    companyName: "Infosys",
    description:
      "The candidates we've hired through your platform have shown remarkable talent and dedication. They have seamlessly integrated with our teams and brought great value to our projects.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/c/cf/Wipro_Primary_Logo_Color_RGB.svg",
    writtenBy: "Rohit Sharma, VP of Engineering",
    companyName: "Wipro",
    description:
      "Working with your team has been an incredible experience. The engineers you provided are highly skilled and have added tremendous value to our ongoing projects.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/3/33/HCLTech_logo.svg",
    writtenBy: "Priya Iyer, Head of Product",
    companyName: "HCLTech",
    description:
      "The quality of engineers we've hired through your platform has been exceptional. They've been instrumental in driving innovation and ensuring the success of our projects.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/1/10/Tech_Mahindra_New_Logo.svg",
    writtenBy: "Suresh Reddy, Lead Architect",
    companyName: "Tech Mahindra",
    description:
      "Your recruitment platform has made our hiring process more efficient. The engineers we've hired have exceeded our expectations in both technical ability and cultural fit.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/4c/LTI_Mindtree_logo.svg",
    writtenBy: "Vikas Mehta, VP of Engineering",
    companyName: "LTI Mindtree",
    description:
      "We have seen significant improvements in our development cycle after hiring through your platform. The engineers are not only skilled but also innovative and proactive in their work.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/e/e5/Cognizant%27s_logo_2022.svg",
    writtenBy: "Sunita Desai, CTO",
    companyName: "Cognizant",
    description:
      "We're extremely pleased with the level of talent we've acquired through your platform. The engineers are well-versed in the latest technologies and have been key to the success of our recent projects.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/a/af/Capgemini_Logo.svg",
    writtenBy: "Rohan Jain, Director of Engineering",
    companyName: "Capgemini",
    description:
      "Your platform has provided us with engineers who are both technically sound and great team players. It has truly simplified our hiring process and brought us incredible talent.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/7/79/Accenture_logo.svg",
    writtenBy: "Kiran Kumar, CEO",
    companyName: "Accenture India",
    description:
      "The hiring experience through your platform has been seamless. The engineers we've onboarded are exceptional and have made a positive impact on our projects from day one.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/d/d6/Deloitte_Logo.png",
    writtenBy: "Shivani Joshi, Engineering Manager",
    companyName: "Deloitte",
    description:
      "The engineers we've hired through your platform have proven to be a perfect fit for our needs. Their technical skills and problem-solving abilities have greatly contributed to our success.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/8/83/IBM_logo.svg",
    writtenBy: "Manoj Gupta, Senior Engineer",
    companyName: "IBM",
    description:
      "Your platform has helped us hire highly qualified engineers quickly and efficiently. They've integrated smoothly into our teams and contributed meaningfully to our projects.",
  },
  {
    companyLogoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/4f/Oracle_logo.svg",
    writtenBy: "Ravi Prasad, VP of Technology",
    companyName: "Oracle India",
    description:
      "The engineers we've hired through your platform have been some of the best talents we've worked with. They are creative, skilled, and have helped us solve complex challenges with ease.",
  },
];

const OnboardHiringPartner = () => {
  const navigate = useNavigate();
  const [testsData, setTeastData] = useState([]);
  const store = useSelector((store) => store?.userLogin);

  const handelDemoSubmit = (values) => {
    performRequest("POST", "bookdemo/createbookdemo", values)
      .then((res) => {
        if (res.status === 201) {
          alert("Your Demo request was successfully upated.");
          document.getElementById("close-modal").click();
        }
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    performRequest("GET", `/test/alltests`)
      .then((res) => {
        setTeastData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  let se1 = 0;
  let sbd1 = 0;
  let abc1 = 0;
  return (
    <div className="Page">
      <div
        className=" modal modal-blur fade"
        id="modal-team"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className=" modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content bg-primary-lt">
            <div className="modal-header">
              <h2 className="mt-3 ">Book Demo</h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <Formik
              initialValues={BookDemoInfo}
              validationSchema={BookDemoSchema}
              onSubmit={handelDemoSubmit}
            >
              {(props) => (
                <Form>
                  <div className="modal-body bg-primary-lt">
                    <div className="mb-3">
                      <label className="form-label required">Full Name</label>
                      <CustomInput name="fullName" type={"text"} />
                      {props.touched.fullName && props.errors.fullName && (
                        <p className="text-danger">{props.errors.fullName}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label required">
                        Company Name
                      </label>
                      <CustomInput name="companyName" />
                      {props.touched.companyName &&
                        props.errors.companyName && (
                          <p className="text-danger">
                            {props.errors.companyName}
                          </p>
                        )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label required">
                        Bussiness Email
                      </label>
                      <CustomInput name="companyEmail" type="email" />
                      {props.touched.companyEmail &&
                        props.errors.companyEmail && (
                          <p className="text-danger">
                            {props.errors.companyEmail}
                          </p>
                        )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label required">
                        Contact Number
                      </label>
                      <CustomInput name="contactNumber" type="text" />
                      {props.touched.contactNumber &&
                        props.errors.contactNumber && (
                          <p className="text-danger">
                            {props.errors.contactNumber}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="modal-footer bg-primary-lt">
                    <button
                      type="button"
                      className="btn me-auto"
                      data-bs-dismiss="modal"
                      id="close-modal"
                    >
                      Close
                    </button>
                    <FormSubmitButton buttonText={"Book Demo"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="page-wrapper">
        <div className="container-xxl">
          <header className="hero text-center py-10 my-5 my-md-8">
            <h1
              className="hero-title aos-init aos-animate my-5 display-4 fw-bold"
              data-aos="zoom-y-out"
            >
              YOUR TEAM SEARCH ENDS HERE!
            </h1>

            <div className="row my-4">
              <div className="col-md-6 text-md-end mb-3">
                {store?.isLoggedIn ? (
                  <span
                    onClick={() => navigate("/my-profile")}
                    className="btn btn-primary btn-lg p-3 btn-text"
                  >
                    <FaBuildingUser fontSize={"1.2em"} className="me-2" />
                    Complete Your Profile
                  </span>
                ) : (
                  <span
                    onClick={() => navigate("/employer-onboarding")}
                    className="btn btn-primary btn-lg p-3 btn-text"
                  >
                    <FaBuildingUser fontSize={"1.2em"} className="me-2" />
                    Create Employer Account
                  </span>
                )}
              </div>
              <div className="col-md-6 text-md-start">
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#modal-team"
                  className="btn btn-lg p-3 btn-text"
                >
                  <FaLaptop fontSize={"1.2em"} className="me-2" />
                  Book A Online Demo
                </span>
              </div>
            </div>
            <p className="hero-description text-secondary fs-2">
              Discover perfect candidate faster.
            </p>
          </header>
          <hr />

          <section className="section text-center my-md-8">
            <div className="section-header">
              <h2 className="section-title section-title-lg m-0 p-0 fs-1">
                WHY COMPANIES HIRE FROM US?
              </h2>
              <p className="section-description m-0 p-0">
                From startups to SMEs to established enterprises; We cater to
                everyone.
              </p>
            </div>
            <div className="my-4">
              <div
                className="row row-deck my-md-3"
                data-aos-id-blocks-previews="true"
              >
                <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                  <div className="card card-stacked card-link card-link-pop">
                    <div className="card-body">
                      <h2 className="fs-2">VERIFIED PROFILES</h2>
                      <p>
                        Each and every profile you shortlist. Undergoes first
                        round of interview via our recruiters. Reducing your
                        hire time & hassle.
                      </p>
                      <a
                        href="/verified-profiles"
                        className="btn btn-outline-primary w-50"
                      >
                        <FaArrowAltCircleRight
                          fontSize={"1.2em"}
                          className="me-2"
                        />
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-2 col-sm-12">
                  <div className="card card-stacked card-link card-link-pop">
                    <div className="card-body">
                      <h3 className="fs-2">ACCOUNT MANAGER</h3>
                      <p>
                        We assign you dedicated account manager to make sure
                        that you get access to the right candidate as per your
                        requirement.
                      </p>
                      <a
                        href="/free-account-manager"
                        className="btn btn-outline-primary w-50"
                      >
                        <FaArrowAltCircleRight
                          fontSize={"1.2em"}
                          className="me-2"
                        />
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-2 col-sm-12">
                  <div className="card card-stacked card-link card-link-pop">
                    <div className="card-body">
                      <h3 className="fs-2">SKILLS VERIFICATION</h3>
                      <p>
                        Every candidate has to go through via Skill test before
                        appearing for the interview. We make sure you don't hire
                        bliendly.
                      </p>
                      <a
                        href="/verified-skills"
                        className="btn btn-outline-primary w-50"
                      >
                        <FaArrowAltCircleRight
                          fontSize={"1.2em"}
                          className="me-2"
                        />
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-2 col-sm-12">
                  <div className="card card-stacked card-link card-link-pop">
                    <div className="card-body">
                      <h3 className="fs-2">AI BASED INTERVIEWS</h3>
                      <p>
                        We derive and give Skill Score to every profile. AI
                        helps you hire the perfect candidate based on your
                        process and culture.
                      </p>
                      <a
                        href="ai-interview-cracker"
                        className="btn btn-outline-primary w-50"
                      >
                        <FaArrowAltCircleRight
                          fontSize={"1.2em"}
                          className="me-2"
                        />
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row my-3 row-deck"
                data-aos-id-blocks-previews="true"
              >
                <div className="col-md-6">
                  <div className="card card-stacked card-link card-link-pop">
                    <div className="card-body">
                      <h3 className="fs-2">HIRE WITHOUT BREAKING BANK</h3>
                      <p>
                        Each and every profile you shortlist. Undergoes first
                        round of interview via our recruiters. Reducing your
                        hire time & hassle.
                      </p>
                      <a
                        href="/hire-without-breaking-bank"
                        className="btn btn-outline-primary w-50"
                      >
                        <FaArrowAltCircleRight
                          fontSize={"1.2em"}
                          className="me-2"
                        />
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card card-stacked card-link card-link-pop">
                    <div className="card-body">
                      <h3 className="fs-2">3 MONTHS FREE REPLACEMENT</h3>
                      <p>
                        We assign you dedicated account manager to make sure
                        that you get access to the right candidate as per your
                        requirement.
                      </p>
                      <a
                        href="/free-3monts-replacement"
                        className="btn btn-outline-primary w-50"
                      >
                        <FaArrowAltCircleRight
                          fontSize={"1.2em"}
                          className="me-2"
                        />
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr />

          <section className="section section-secondary text-center my-5 my-md-8">
            <div className="section-header">
              <h2 className="section-title section-title-lg fs-1 m-0 p-0">
                POST A JOB IN 60 SECONDS!
              </h2>
              <p className="section-description m-0 p-0">
                Revolutionize your hiring with our AI-powered algorithm.
              </p>
            </div>
            <div
              className="row row-deck my-4"
              data-aos-id-blocks-previews="true"
            >
              <div className="col-md-6 align-items-center ">
                <div className="card my-4 align-items-center ">
                  <img
                    width={"100%"}
                    src="https://www.partners-popdev.org/wp-content/uploads/2022/01/PPD-Job-Announcement-01.gif"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 text-md-start">
                <div className="">
                  <div className="card my-4">
                    <div className="card-status-start bg-primary"></div>
                    <div className="card-body">
                      <h3 className="card-title">
                        <FaAngleDoubleRight
                          fontSize={"1.2em"}
                          className="me-2"
                        />
                        SIMPLIFIED JOB POSTING!
                      </h3>
                      <p>
                        Post your job in less then 60 seconds. Genrative AI
                        based Job posting makes it easy to do skill mapping at
                        lowest data points available.
                      </p>
                    </div>
                  </div>
                  <div className="card my-4">
                    <div className="card-status-start bg-primary"></div>
                    <div className="card-body">
                      <h3 className="card-title">
                        <FaAngleDoubleRight
                          fontSize={"1.2em"}
                          className="me-2"
                        />
                        GET UNLIMITED APPLICATIONS!
                      </h3>
                      <p>
                        We don't hide profiles behind pay button. Get unlimited
                        profiles to shortlist from. More choices leads to better
                        decision.
                      </p>
                    </div>
                  </div>
                  <div className="card my-4">
                    <div className="card-status-start bg-primary"></div>
                    <div className="card-body">
                      <h3 className="card-title">
                        <FaAngleDoubleRight
                          fontSize={"1.2em"}
                          className="me-2"
                        />
                        90% BETTER ROI THAN MARKET!
                      </h3>
                      <p>
                        The amount & Time you invest on HIRE48 will be always
                        less then compare to any platform you could think of out
                        there! We challange that.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => navigate("/create-jobs")}
              className="btn btn-primary w-20"
            >
              <FaArrowCircleRight fontSize={"1em"} className="me-2" /> CREATE
              JOB POST
            </button>
          </section>
          <hr />

          <section className="section section-secondary text-center my-5 my-md-8">
            <div className="section-header">
              <h2 className="section-title section-title-lg fs-1 m-0 p-0">
                HOW WE WORK?
              </h2>
              <p className="section-description m-0 p-0">
                Our hiring process will help you understand how we manage to get
                you best candidate at best price with lowest attrition rate..
              </p>
            </div>
            <div className="row my-4" data-aos-id-blocks-previews="true">
              <div className="col-lg-3  col-md-6 mb-2">
                <div className="card card-stacked">
                  <div className="card-body">
                    <h3 className="m-0 p-0">CANDIDATE PROOFING</h3>
                    <p className="fs-4">STEP 1</p>
                    <p>
                      Unlike other brands; We don't run behind numbers. Every
                      candidate join us underrgoes Soft Skill Traing and pass
                      related tests.
                    </p>
                    <p>
                      Candidates who pass this tests are the only candidates get
                      to apply for any job on platform.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-2">
                <div className="card card-stacked">
                  <div className="card-body">
                    <h3 className="m-0 p-0">RECRUITER ACCESS</h3>
                    <p className="fs-4">STEP 2</p>
                    <p>
                      Once you post your job. Our recruiters starts connecting
                      candidates based on profile relevancy score and start
                      interviewing them.
                    </p>
                    <p>
                      For every post, recruiters interview 20 to 30 candidates
                      and filter out 5 to 10 worthy profiles for your review.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-2">
                <div className="card card-stacked">
                  <div className="card-body">
                    <h3 className="m-0 p-0">BACKGROUND VERIFICATION</h3>
                    <p className="fs-4">STEP 3</p>
                    <p>
                      Once you shortlist candidates out of list provided to you.
                      We do background check based on resume they provided for
                      skills and employment history.
                    </p>
                    <p>
                      In Background verification, We also verify salary,
                      experience, code of conduct and releiving letter.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-2">
                <div className="card card-stacked">
                  <div className="card-body">
                    <h3 className="m-0 p-0">FINAL INTERVIEW</h3>
                    <p className="fs-4">STEP 4</p>
                    <p>
                      At this stage we are left with only those people who are
                      good to go for in-person or video call interview.
                    </p>
                    <p>
                      Once you finalize candidates from list we close the
                      position, in case we are not able to close, we consider
                      few more candidates from Step 2.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr />

          <section className="section section-secondary text-center my-5 my-md-8">
            <div className="section-header">
              <h2 className="section-title section-title-lg fs-1 m-0 p-0">
                NOT JUST SKILLS IN WRITING.
              </h2>
              <p className="section-description">
                Get skilled proofing of candidates before you even interview.
              </p>
            </div>
            <div className="row my-4">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="card card-stacked">
                  <div className="card-header">
                    <h3 className="card-title">Software Engineering</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card my-2">
                          <div className="row g-0">
                            {testsData?.map((testdep) => {
                              if (
                                se1 < 3 &&
                                testdep.department === "Software Engineering"
                              ) {
                                se1++;
                                return (
                                  <div key={testdep?.id} className="col-4">
                                    <div className="card-body">
                                      <div
                                        style={{
                                          backgroundImage: `url(${testdep.icon})`,
                                        }}
                                        className="avatar avatar-lg"
                                      ></div>
                                      <p
                                        style={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 2,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {testdep.name}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                        <div className="card my-2">
                          <div className="ribbon bg-red">Advance</div>
                          <div className="row g-0">
                            {testsData?.map((testdep, index) => {
                              if (
                                se1 > 5 &&
                                se1 < 9 &&
                                testdep.department === "Software Engineering"
                              ) {
                                se1++;
                                return (
                                  <div key={testdep?.id} className="col-4">
                                    <div className="card-body">
                                      <div
                                        style={{
                                          backgroundImage: `url(${testdep.icon})`,
                                        }}
                                        className="avatar avatar-lg"
                                      ></div>
                                      <p
                                        style={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 1,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {testdep.name}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                              if (
                                testdep.department === "Software Engineering"
                              ) {
                                se1++;
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="card card-stacked">
                  <div className="card-header">
                    <h3 className="card-title">Sales & Business Development</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card my-2">
                          <div className="row g-0">
                            {testsData?.map((testdep, index) => {
                              if (
                                sbd1 < 3 &&
                                testdep.department ===
                                  "Sales & Business Development"
                              ) {
                                sbd1++;
                                return (
                                  <div key={testdep?.id} className="col-4">
                                    <div className="card-body">
                                      <div
                                        style={{
                                          backgroundImage: `url(${testdep.icon})`,
                                        }}
                                        className="avatar avatar-lg"
                                      ></div>
                                      <p
                                        style={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 1,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {testdep.name}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                        <div className="card my-2">
                          <div className="ribbon bg-red">Advance</div>
                          <div className="row g-0">
                            {testsData?.map((testdep, index) => {
                              if (
                                sbd1 > 5 &&
                                sbd1 < 9 &&
                                testdep.department ===
                                  "Sales & Business Development"
                              ) {
                                sbd1++;
                                return (
                                  <div key={testdep?.id} className="col-4">
                                    <div className="card-body">
                                      <div
                                        style={{
                                          backgroundImage: `url(${testdep.icon})`,
                                        }}
                                        className="avatar avatar-lg"
                                      ></div>
                                      <p
                                        style={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 1,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {testdep.name}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                              if (
                                testdep.department ===
                                "Sales & Business Development"
                              ) {
                                sbd1++;
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="card card-stacked">
                  <div className="card-header">
                    <h3 className="card-title">ADMIN & BACK OFFICE</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card my-2">
                          <div className="row g-0">
                            {testsData?.map((testdep, index) => {
                              if (
                                abc1 < 3 &&
                                testdep.department === "Admin & Back Office"
                              ) {
                                abc1++;
                                return (
                                  <div key={testdep?.id} className="col-4">
                                    <div className="card-body">
                                      <div
                                        style={{
                                          backgroundImage: `url(${testdep.icon})`,
                                        }}
                                        className="avatar avatar-lg"
                                      ></div>
                                      <p
                                        style={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 1,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {testdep.name}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                        <div className="card my-2">
                          <div className="ribbon bg-red">Advance</div>
                          <div className="row g-0">
                            {testsData?.map((testdep, index) => {
                              if (
                                abc1 > 5 &&
                                abc1 < 9 &&
                                testdep.department === "Admin & Back Office"
                              ) {
                                abc1++;
                                return (
                                  <div key={testdep?.id} className="col-4">
                                    <div className="card-body">
                                      <div
                                        style={{
                                          backgroundImage: `url(${testdep.icon})`,
                                        }}
                                        className="avatar avatar-lg"
                                      ></div>
                                      <p
                                        style={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 1,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {testdep.name}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                              if (
                                testdep.department === "Admin & Back Office"
                              ) {
                                abc1++;
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <button
              className="btn btn-primary w-20"
              onClick={() => navigate(`/post-contest`)}
            >
              <FaArrowCircleRight fontSize={"1em"} className="me-2" />
              CREATE YOUR CUSTOM TEST
            </button> */}
          </section>
          <hr />

          <section className="section section-secondary text-center my-5 my-md-8">
            <div className="section-header">
              <h2 className="section-title section-title-lg fs-1 m-0 p-0">
                TESTIMONIALS.
              </h2>
              <p className="section-description">
                What other companies think about us.
              </p>
            </div>

            <div
              className="row row-deck row-cards my-3"
              data-aos-id-blocks-previews="true"
            >
              {testimonials?.map((el, index) => {
                return (
                  <div key={index} className="col-md-3">
                    <div className="card bg-primary-lt">
                      <div className="card-body">
                        <div
                          className="avatar avatar-xl"
                          style={{
                            backgroundImage: `url(${el.companyLogoUrl})`,
                          }}
                        ></div>
                        <h3 className="m-0">{el.companyName}</h3>
                        <p className="text-secondary mb-3">{el.writtenBy}</p>
                        <p>{el.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>

          <section className="section section-secondary text-center my-10">
            <div className="row" data-aos-id-blocks-previews="true">
              <div className="col-12">
                <div className="card bg-secondary-lt">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-12 col-sm-12 ">
                        <h2 className="section-title section-title-lg">
                          Hire From Every Corner!
                        </h2>
                        <p className="section-description">
                          HIRE48 in widespread across <strong>INDIA</strong>!
                        </p>
                      </div>
                      <div className="col-lg-9 col-md-12 col-sm-12">
                        <div className="btn-list">
                          <button className="btn">
                            Mumbai{" "}
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Delhi / NCR
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Pune
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Ahmedabad
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Bengaluru
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Chennai
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Kolkata
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Lucknow
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Hyderabad
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Surat
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Nagpur
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Nashik
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Chandigarh
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Coimbatore
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Jaipur
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Kochi
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Trivandrum
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Indore
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Vadodara
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Aurangabad
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Ahmednagar
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Amravati
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Dhule
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Latur
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Solapur
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Kolhapur
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Akola
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Nanded
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Jalgao
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Thanjavur
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Bulandshahr
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Sambhal
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Singrauli
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Yamunanagar
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Pallavaram
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Panchkula
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Burhanpur
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Kharagpur
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Dindigul
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Gandhinagar
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Deoghar
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Khandwa
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Munger
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Katihar
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Thoothukudi
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Rewa
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Mirzapur
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Raichur
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Haridwar
                            <span className="badge bg-blue text-blue-fg ms-2">
                              134
                            </span>
                          </button>
                          <button className="btn">
                            Other 684 cities{" "}
                            <span className="badge bg-blue text-blue-fg ms-2">
                              22134
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default OnboardHiringPartner;
