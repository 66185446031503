import React from "react";

// import { FaXmarksLines, FaArrowCircleRight } from "react-icons/fa6";

const SimpleButton = ({ type, buttonText, onClick, ...props }) => {
  buttonText = buttonText.toUpperCase();
  const handleCancel = () => {
    window.history.back();
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <a
      className={`btn mx-1 mt-2 btn-${type || "primary"}`}
      onClick={
        buttonText === "CANCEL" || buttonText === "CLOSE"
          ? handleCancel
          : handleClick
      }
      {...props}
    >
      {buttonText}
    </a>
  );
};

export default SimpleButton;
