import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
const cookie = Cookies.get("user") || null;

let userData = null;
if (cookie) {
  userData = JSON.parse(cookie);
}
export const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoggedIn: userData?.Token ? true : false,
    user: userData ? userData : null,
  },
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      Cookies.set("user", JSON.stringify(action.payload), { expires: 7 });
    },
    logout: (state) => {
      Cookies.remove("user");
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    },
  },
});

export const { login, logout } = loginSlice.actions;
export default loginSlice.reducer;
